
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import FormatKey from "../Modules/FormatKey";
import { RaceContext } from "../Pages/RaceContext";
import Clock from "../Modules/MathTime/Clock";
import EnterRaceForm from "../Users/EnterRaceForm"; // Import EnterRaceForm

const RaceCards = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const raceId = searchParams.get("raceId");
  const trackId = searchParams.get("trackId");
  const [raceData, setRaceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newHorsesData, setNewHorsesData] = useState([]);
  const [horseDetails, setHorseDetails] = useState({});
  const [jockeyDetails, setJockeyDetails] = useState({});
  const [trainerDetails, setTrainerDetails] = useState({});
  const [ownerDetails, setOwnerDetails] = useState({});
  const [selectedHorseId, setSelectedHorseId] = useState(null); // Changed to single selected horse ID
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const {
    setRace,
    setCurrentRaceId,
    setNavigateData,
    setSelectedHorseIds: setRaceContextHorseIds,
    setSelectedHorseNames,
    numHorses,
    setNumHorses,
    currentRaceId,
    trackName,
    postTime,
    setPostTime,
  } = useContext(RaceContext);

  useEffect(() => {
    const fetchRaceData = async () => {
      try {
        const raceNumHorsesResponse = await axios.get(
          `https://hotponies.com/races/${raceId}`
        );
        let raceNumHorses = raceNumHorsesResponse.data.num_horses;

        setNumHorses(raceNumHorses);

        if (raceNumHorses < 6 || raceNumHorses > 12) {
          raceNumHorses = Math.floor(Math.random() * 7) + 9;
        }

        const raceResponse = await axios.get(
          `https://hotponies.com/query/current_races/race_id/${raceId}`
        );
        const today = new Date();
        today.setUTCHours(0, 0, 0, 0);

        let currentRaceResults = raceResponse.data.filter((result) => {
          const raceDate = new Date(result.race_date);
          const trackIdInt = parseInt(result.track_id);
          const raceIdInt = parseInt(result.race_id);
          raceDate.setUTCHours(0, 0, 0, 0);
          if (typeof result.post_time === "string") {
            setPostTime(result.post_time);
          }
          console.log("RACECARD RESULT POST TIME:", result.post_time);
          return (
            trackIdInt === parseInt(trackId) &&
            raceIdInt === parseInt(raceId) &&
            raceDate >= today
          );
        });

        if (currentRaceResults.length === 0) {
          console.warn(`Not enough valid races found for race ID ${raceId}`);
          setRaceData([]);
          setLoading(false);
          return;
        }

        const patchPromises = [];

        for (let currentRaceResult of currentRaceResults) {
          if (currentRaceResult.num_horses !== raceNumHorses) {
            const patchData = {
              num_horses: raceNumHorses,
              num_jockeys: raceNumHorses,
            };
            patchPromises.push(
              axios.patch(
                `https://hotponies.com/current_races/${currentRaceResult.id}`,
                patchData
              )
            );
          }
        }

        for (let currentRaceResult of currentRaceResults) {
          const horseKeys = Object.keys(currentRaceResult).filter((key) =>
            key.startsWith("horse_id")
          );
          const qualifiedHorseKeys = horseKeys.filter(
            (horseKey) => currentRaceResult[horseKey] >= 1
          );

          const jockeyKeys = Object.keys(currentRaceResult).filter((key) =>
            key.startsWith("jockey_id")
          );
          const qualifiedJockeyKeys = jockeyKeys.filter(
            (jockeyKey) => currentRaceResult[jockeyKey] >= 1
          );

          if (
            qualifiedHorseKeys.length < raceNumHorses ||
            qualifiedJockeyKeys.length < 6
          ) {
            const randomValue = Math.floor(Math.random() * 7) + 9;
            const additionalHorses = await axios.get(
              `https://hotponies.com/random-records/horses/${randomValue}`
            );
            const additionalJockeys = await axios.get(
              `https://hotponies.com/random-records/jockeys/${randomValue}`
            );

            let patchData = {};

            let horseCounter = qualifiedHorseKeys.length;

            while (
              horseCounter < raceNumHorses &&
              horseCounter < additionalHorses.data.length
            ) {
              const horseKey = `horse_id${horseCounter + 1}`;
              currentRaceResult[horseKey] =
                additionalHorses.data[horseCounter].id;
              patchData[horseKey] = additionalHorses.data[horseCounter].id;
              horseCounter++;
            }

            let jockeyCounter = 0;
            for (let jockeyKey of jockeyKeys) {
              if (
                currentRaceResult[jockeyKey] < 1 &&
                jockeyCounter < additionalJockeys.data.length
              ) {
                currentRaceResult[jockeyKey] =
                  additionalJockeys.data[jockeyCounter].id;
                patchData[jockeyKey] = additionalJockeys.data[jockeyCounter].id;
                jockeyCounter++;
              }
            }

            if (Object.keys(patchData).length > 0) {
              patchPromises.push(
                axios.patch(
                  `https://hotponies.com/current_races/${currentRaceResult.id}`,
                  patchData
                )
              );
            } else {
              console.warn(
                "No data to patch for race ID:",
                currentRaceResult.id
              );
            }
          }
        }

        await Promise.all(patchPromises);
        setRaceData(currentRaceResults);

        const horseIds = currentRaceResults.flatMap(race => 
          Object.keys(race)
            .filter(key => key.startsWith("horse_id"))
            .map(key => race[key])
            .filter(id => id >= 1)
        );
        setRaceContextHorseIds(horseIds); // Ensure these are set in context
        // Set the selectedHorseId only if it is not already set
        if (!selectedHorseId) {
          setSelectedHorseId(horseIds[0]); // Default to the first horse ID
        }
        console.log("Selected Horse IDs:", horseIds); // Ensure this prints correct IDs
      } catch (error) {
        console.error("Error fetching race data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRaceData();

    const fetchInterval = setInterval(() => {
      fetchRaceData();
    }, 3000);

    return () => clearInterval(fetchInterval);
  }, [raceId, trackId, setRaceContextHorseIds, selectedHorseId]);

  useEffect(() => {
    const raceDetails = async () => {
      try {
        const newHorses = [];
        const fetchedHorseDetails = {};
        const fetchedJockeyDetails = {};
        const fetchedTrainerDetails = {};
        const fetchedOwnerDetails = {};
        const fetchedHorseIdsSet = new Set();
        const fetchedJockeyIdsSet = new Set();
        const fetchedTrainerIdsSet = new Set();
        const fetchedOwnerIdsSet = new Set();

        const currentRaceId = raceData.length > 0 ? raceData[0].id : null;
        if (currentRaceId) {
          setCurrentRaceId(currentRaceId);
        }

        const fetchHorseDetails = async (id) => {
          if (!fetchedHorseIdsSet.has(id)) {
            const response = await axios.get(
              `https://hotponies.com/horses/${id}`
            );
            fetchedHorseDetails[id] = response.data.horse_name;
            fetchedHorseIdsSet.add(id);
          }
        };

        const fetchJockeyDetails = async (horseId, jockeyId) => {
          if (!fetchedJockeyIdsSet.has(jockeyId)) {
            const jockeyResponse = await axios.get(
              `https://hotponies.com/jockeys/${jockeyId}`
            );
            fetchedJockeyDetails[horseId] = {
              horseName: horseDetails[horseId],
              jockeyName: jockeyResponse.data.jockey_name,
            };
            fetchedJockeyIdsSet.add(jockeyId);
          }
        };

        const fetchTrainerDetails = async (horseId) => {
          if (!fetchedTrainerIdsSet.has(horseId)) {
            const horseResponse = await axios.get(
              `https://hotponies.com/horses/${horseId}`
            );
            const horseName = horseResponse.data.horse_name;
            const trainerId = horseResponse.data.trainer_id;
            const trainerResponse = await axios.get(
              `https://hotponies.com/trainers/${trainerId}`
            );
            fetchedTrainerDetails[horseId] = {
              horseName,
              trainerId,
              trainerName: trainerResponse.data.trainer_name,
            };
            fetchedTrainerIdsSet.add(horseId);
          }
        };

        const fetchOwnerDetails = async (horseId) => {
          if (!fetchedOwnerIdsSet.has(horseId)) {
            const horseResponse = await axios.get(
              `https://hotponies.com/horses/${horseId}`
            );
            const horseName = horseResponse.data.horse_name;
            const ownerId = horseResponse.data.owner_id;
            const ownerResponse = await axios.get(
              `https://hotponies.com/owners/${ownerId}`
            );
            fetchedOwnerDetails[horseId] = {
              horseName,
              ownerId,
              ownerName: ownerResponse.data.owner_name,
            };
            fetchedOwnerIdsSet.add(horseId);
          }
        };

        for (let i = 0; i < raceData.length; i++) {
          const horseIds = Object.keys(raceData[i])
            .filter((key) => key.startsWith("horse_id"))
            .map((key) => raceData[i][key])
            .filter((id) => id >= 1);
          const jockeyIds = Object.keys(raceData[i])
            .filter((key) => key.startsWith("jockey_id"))
            .map((key) => raceData[i][key])
            .filter((id) => id >= 1);
          const pairsLength = Math.min(horseIds.length, jockeyIds.length);
          for (let j = 0; j < pairsLength; j++) {
            newHorses.push({
              horse_id: horseIds[j],
              jockey_id: jockeyIds[j],
            });
          }
          for (const id of horseIds) {
            await fetchHorseDetails(id);
          }
          for (let j = 0; j < pairsLength; j++) {
            const horseId = horseIds[j];
            const jockeyId = jockeyIds[j];
            await fetchJockeyDetails(horseId, jockeyId);
          }

          for (const horseId of horseIds) {
            await fetchTrainerDetails(horseId);
          }

          for (const horseId of horseIds) {
            await fetchOwnerDetails(horseId);
          }
        }
        setHorseDetails(fetchedHorseDetails);
        setJockeyDetails(fetchedJockeyDetails);
        setNewHorsesData(newHorses);
        setTrainerDetails(fetchedTrainerDetails);
        setOwnerDetails(fetchedOwnerDetails);
      } catch (error) {
        console.error("Error fetching new horse data:", error);
      }
    };

    raceDetails();
  }, [raceData, setCurrentRaceId]);

  useEffect(() => {
    const fetchHorseDetails = async () => {
      try {
        let promises = [];

        newHorsesData.forEach((horse) => {
          promises.push(
            axios.get(`https://hotponies.com/horses/${horse.horse_id}`)
          );
        });

        const responses = await Promise.all(promises);

        let fetchedDetails = {};

        responses.forEach((response) => {
          const horseData = response.data;
          fetchedDetails[horseData.id] = horseData.horse_name;
        });

        setHorseDetails((prevHorseDetails) => ({
          ...prevHorseDetails,
          ...fetchedDetails,
        }));
      } catch (error) {
        console.error("Error fetching horse details:", error);
      }
    };

    const fetchData = async () => {
      if (newHorsesData.length > 0) {
        await fetchHorseDetails();
      }
    };

    fetchData();
  }, [newHorsesData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleNavigateToBets = async () => {
    setRace(raceData);
    setNavigateData({
      raceId,
      numHorses,
    });
    setRaceContextHorseIds(
      newHorsesData.slice(0, numHorses).map((h) => h.horse_id)
    );
    setSelectedHorseNames(
      newHorsesData
        .slice(0, numHorses)
        .map((h) => horseDetails[h.horse_id] || "Fetching...")
    );
    navigate("/bets");
  };

  const handleHorseSelection = (horse) => {
    setSelectedHorseId(horse.horse_id);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ marginTop: "2%", display: "flex" }}>
      <div style={{ flex: "1", marginRight: "2%" }}>
        <button onClick={handleGoBack} style={{ marginLeft: "3px" }}>
          Go Back
        </button>
        <button onClick={handleNavigateToBets} style={{ marginLeft: "1vw" }}>
          Place Bet
        </button>
        <button onClick={() => navigate("/bet-odds")} style={{ marginLeft: "1vw" }}>
          Bet Odds
        </button>
        <button onClick={() => navigate("/predict-race")} style={{ marginLeft: "1vw" }}>
          Predict Race
        </button>
        <button onClick={() => navigate("/morning-line-odds")} style={{ marginLeft: "1vw" }}>
          Morning Line Odds
        </button>
        <Clock showCountdown={true} />
        {/* <button onClick={openModal} style={{ marginLeft: "1vw" }}>
          Enter Race
        </button> */}
  
        <h2>CURRENT RACE CARD:</h2>
        {loading ? (
          <p>Loading race data...</p>
        ) : raceData.length > 0 ? (
          <>
            <table>
              <thead>
                <tr>
                  {Object.keys(raceData[0])
                    .slice(0, 9)
                    .map((key, idx) => (
                      <th key={idx}>
                        <FormatKey keyText={key} />
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {raceData.map((race, rIdx) => (
                  <tr key={rIdx}>
                    {Object.keys(race)
                      .slice(0, 9)
                      .map((key, cIdx) => (
                        <td key={cIdx}>{race[key]}</td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
  
            <h2>RACE DETAILS:</h2>
            <table>
              <thead>
                <tr>
                  <th>Post #</th>
                  <th>Horse #</th>
                  <th>Horse</th>
                  <th>Jockey</th>
                  <th>Trainer</th>
                  <th>Owner</th>
                </tr>
              </thead>
              <tbody>
                {newHorsesData.slice(0, numHorses).map((horse, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="selectedHorse"
                        checked={selectedHorseId === horse.horse_id}
                        onChange={() => handleHorseSelection(horse)}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{horse.horse_id}</td>
                    <td
                      onClick={() => navigate(`/page-horse/${horse.horse_id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {horseDetails[horse.horse_id] || "Fetching..."}
                    </td>
                    <td
                      onClick={() => navigate(`/page-jockey/${horse.horse_id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      {jockeyDetails[horse.horse_id]
                        ? jockeyDetails[horse.horse_id].jockeyName
                        : "Fetching..."}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/page-trainer/${trainerDetails[horse.horse_id]?.trainerId}`
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {trainerDetails[horse.horse_id]
                        ? trainerDetails[horse.horse_id].trainerName
                        : "Fetching..."}
                    </td>
                    <td
                      onClick={() => {
                        navigate(
                          `/page-owner/${ownerDetails[horse.horse_id]?.ownerId}`
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {ownerDetails[horse.horse_id]
                        ? ownerDetails[horse.horse_id].ownerName
                        : "Fetching..."}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <p>No race data available.</p>
        )}
  
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Enter Race"
          ariaHideApp={false}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <EnterRaceForm />
          <button onClick={closeModal}>Close</button>
        </Modal>
      </div>
    </div>
  );
  
};

export default RaceCards;
