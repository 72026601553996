import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const LazyLoadDropdown = ({ apiEndpoint, label, value, onChange, displayProperty, idProperty }) => {
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const dropdownRef = useRef(null);
  const fetchedIds = useRef(new Set()); // Track fetched IDs to prevent duplicates

  useEffect(() => {
    fetchOptions(page);
  }, [page]);

  const fetchOptions = async (page) => {
    if (!hasMore || loading) return;

    setLoading(true);
    console.log(`Fetching ${label} options, page: ${page}`);
    try {
      const response = await axios.get(`${apiEndpoint}?start=${(page - 1) * 20}&limit=20`);
      console.log(`Response data for ${label}, page ${page}:`, response.data);

      if (response.data.length > 0) {
        const newOptions = response.data.filter(option => {
          if (fetchedIds.current.has(option[idProperty])) {
            return false;
          } else {
            fetchedIds.current.add(option[idProperty]);
            return true;
          }
        });
        setOptions(prevOptions => [...prevOptions, ...newOptions]);
        setHasMore(response.data.length === 20);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error(`Error fetching ${label.toLowerCase()} options:`, error);
    }
    setLoading(false);
  };

  const handleScroll = () => {
    const dropdown = dropdownRef.current;
    if (dropdown && dropdown.scrollHeight - dropdown.scrollTop <= dropdown.clientHeight + 1 && !loading && hasMore) {
      console.log(`Dropdown scrolled to bottom, loading next page: ${page + 1}`);
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    const dropdown = dropdownRef.current;
    if (dropdown) {
      dropdown.addEventListener('scroll', handleScroll);
      return () => dropdown.removeEventListener('scroll', handleScroll);
    }
  }, [loading, hasMore]);

  return (
    <div>
      <label>{label}:</label>
      <select
        ref={dropdownRef}
        value={value}
        onChange={onChange}
        size="10" // This makes the dropdown larger to allow scrolling
        style={{ width: '100%' }}
      >
        <option value="">Select {label}</option>
        {options.map(option => (
          <option key={`${label}-${option[idProperty]}`} value={option[idProperty]}>
            {option[displayProperty]}
          </option>
        ))}
      </select>
      {loading && <p>Loading more {label.toLowerCase()}s...</p>}
    </div>
  );
};

export default LazyLoadDropdown;

