
import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode.react';

// Login component passes the prop domainName
const SensiPassLogin = ({ domainName }) => {
  // This function handles the login process with SensiPass
  const handleLogin = () => {
    try {
      // Generate a unique user ID using UUID
      const tuuid = uuidv4();

      // Store the generated user ID in the browser's local storage
      localStorage.setItem('tuuid', tuuid);

      const redirectURL ='https://3fasecurity.com/scanner.html';

      // Redirect the user's browser to SensiPass
      window.location.href = redirectURL;
    } catch (error) {
      console.error('Error during SensiPass login:', error.message);
    }
  };

  // This effect runs when the component is mounted
  useEffect(() => {
    // Set up a timeout to clear the stored user ID after 30 seconds
    const expirationTimeout = setTimeout(() => {
      localStorage.removeItem('tuuid'); // Remove the user ID from local storage
    }, 60000); // 60 seconds in milliseconds

    // This cleanup function runs when the component is unmounted
    return () => {
      clearTimeout(expirationTimeout); // Clear the timeout to avoid memory leaks
      // alert("Testing -Expired Token", expirationTimeout)
      console.log('TOKEN:', expirationTimeout);
    };
  }, []);

  // Render a button that triggers the SensiPass login process when clicked
  return (
    <button
      onClick={handleLogin}
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-1%',
        marginBottom: '1%',
      }}
    >
      SensiPass Login
    </button>
  );
};

export default SensiPassLogin;





