import React from 'react';

function JockeyHistory(props) {
  return (
    <div>
      <h1>JOCKEY HISTORY</h1>
    </div>
  );
}

export default JockeyHistory;
