import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { RaceContext } from '../Pages/RaceContext';

function PredictRace() {
  const { selectedHorseIds } = useContext(RaceContext);
  const horseIds = selectedHorseIds || [];

  const [performanceData, setPerformanceData] = useState([]);
  const [topThree, setTopThree] = useState([]);
  const [weights, setWeights] = useState({
    win_rate: 0.2,
    average_speed: 0.3,
    earnings: 0.1,
    last_race_time: 0.3,
    jockey_win_rate: 0.1,
  });

  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        if (horseIds.length === 0) {
          throw new Error('No horse IDs provided');
        }

        const promises = horseIds.map(horseId =>
          axios.get(`https://hotponies.com/past_performance/horse_id/${horseId}`)
            .then(response => response.data)
            .catch(err => {
              console.error('Failed to fetch for horseId:', horseId, err);
              return null;
            })
        );

        const results = await Promise.all(promises);
        const validResults = results.filter(result => result !== null);
        const scoredData = calculateScores(validResults);
        setPerformanceData(scoredData);

        if (scoredData.length > 0) {
          const sortedData = scoredData.sort((a, b) => b.score - a.score);
          setTopThree(sortedData.slice(0, 3));
        }
      } catch (error) {
        console.error('Error fetching performance data:', error);
      }
    };

    fetchPerformanceData();
  }, [horseIds, weights]);

  const calculateScores = (data) => {
    return data.map(horse => {
      const score = calculateScore(horse);
      return { ...horse, score };
    });
  };

  const calculateScore = (horse) => {
    const { win_rate, average_speed, earnings, last_race_time, jockey_win_rate } = weights;

    console.log(`Values for ${horse.horse_name}:`, {
      win_rate: horse.win_pct_horse,
      average_speed: horse.avg_speed_figure_horse,
      earnings: horse.earnings,
      last_race_time: horse.last_race_time,
      jockey_win_rate: horse.win_percentage_jockey
    });

    return (
      (parseFloat(horse.win_pct_horse) || 0) * win_rate +
      (parseFloat(horse.avg_speed_figure_horse) || 0) * average_speed +
      (Math.log(parseFloat(horse.earnings) || 1)) * earnings +
      (1 / (parseFloat(horse.last_race_time) || 1)) * last_race_time +
      (parseFloat(horse.win_percentage_jockey) || 0) * jockey_win_rate
    );
  };

  const handleWeightChange = (event) => {
    const { name, value } = event.target;
    setWeights(prevWeights => ({
      ...prevWeights,
      [name]: parseFloat(value)
    }));
  };

  return (
    <div>
      <h1>Race Predictor</h1>
      <h2>Adjust Weights</h2>
      <div>
        <label>
          Win Rate:
          <input
            type="number"
            name="win_rate"
            value={weights.win_rate}
            onChange={handleWeightChange}
            step="0.01"
            min="0"
            max="1"
          />
        </label>
        <label>
          Average Speed:
          <input
            type="number"
            name="average_speed"
            value={weights.average_speed}
            onChange={handleWeightChange}
            step="0.01"
            min="0"
            max="1"
          />
        </label>
        <label>
          Earnings:
          <input
            type="number"
            name="earnings"
            value={weights.earnings}
            onChange={handleWeightChange}
            step="0.01"
            min="0"
            max="1"
          />
        </label>
        <label>
          Last Race Time:
          <input
            type="number"
            name="last_race_time"
            value={weights.last_race_time}
            onChange={handleWeightChange}
            step="0.01"
            min="0"
            max="1"
          />
        </label>
        <label>
          Jockey Win Rate:
          <input
            type="number"
            name="jockey_win_rate"
            value={weights.jockey_win_rate}
            onChange={handleWeightChange}
            step="0.01"
            min="0"
            max="1"
          />
        </label>
      </div>
      <h2>Top Three Horses</h2>
      <ul>
        {topThree.map(horse => (
          <li key={horse.horse_id}>{horse.horse_name} (Score: {horse.score.toFixed(2)})</li>
        ))}
      </ul>
      <table>
        <thead>
          <tr>
            <th>Horse Name</th>
            <th>Horse ID</th>
            <th>Jockey ID</th>
            <th>Morning Line</th>
          </tr>
        </thead>
        <tbody>
          {performanceData.map(horse => (
            <tr key={horse.horse_id}>
              <td>{horse.horse_name}</td>
              <td>{horse.horse_id}</td>
              <td>{horse.jockey_id}</td>
              <td>{horse.score !== undefined ? horse.score.toFixed(2) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PredictRace;
