import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import FormatKey from '../../Modules/FormatKey';

function Profile() {
  const [columns, setColumns] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({});
  const [numColumns, setNumColumns] = useState(10);
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    axios
      .get(`https://hotponies.com/users/${userId}`)
      .then(response => {
        const userData = response.data;
        setColumns(Object.keys(userData));
        setUsers([userData]);
        setUser(userData);
        setSelectedUser(userData);
        setFormData(userData);
      })
      .catch(error => console.error(error));
  }, []);

  const generateQRCodeData = () => {
    const currentTimestamp = new Date().getTime();
    return `${selectedUser.uuid}?timestamp=${currentTimestamp}/hotponies.com`;
  };

  function handleToggleEditMode() {
    setEditMode(!editMode);
  }

  function handleInputChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    axios
      .put(`https://hotponies.com/users/${selectedUser.id}`, formData)
      .then(response => {
        const updatedUsers = [...users];
        const index = updatedUsers.findIndex(user => user.id === response.data.id);
        updatedUsers[index] = response.data;
        setUsers(updatedUsers);
        setSelectedUser(response.data);
        setFormData(response.data);
        setEditMode(false);
      })
      .catch(error => console.error(error));
  }

  function handleClearForm() {
    setSelectedUser(user);
    setFormData(user);
    setEditMode(false);
  }

  function handleNumColumnsChange(event) {
    const value = parseInt(event.target.value, 10);
    setNumColumns(value);
    if (value > 0) {
      setFormData(prevFormData => {
        const formDataSubset = {};
        columns.slice(6, 6 + value).forEach(column => {
          formDataSubset[column] = prevFormData[column];
        });
        return formDataSubset;
      });
    } else {
      setNumColumns(columns.length - 6);
      setFormData(user);
    }
  }

  function handleViewBets() {
    navigate(`/user-bets?id=${user.id}`);
  }

  function handleGoBack() {
    navigate(-1);
  }

  if (!user) {
    return <div style={{ textAlign: 'center', fontSize: '30px', marginTop: '200px' }}>Please log in to view your profile.</div>;
  }

  return (
    <div style={{ marginLeft: '2vw' }}>
      <button onClick={handleGoBack} style={{ marginTop: '4vh' }}>Go Back</button>
      <div>
        <QRCode value={generateQRCodeData()} size={128} style={{ display: 'block', marginRight: '2vw', marginTop: '8px', marginLeft: 'auto' }} />
        <p style={{ textAlign: 'end' }}>Scan the QR code <br /> to Validate Sensipass</p>
      </div>

      <h2 style={{ marginTop: '-8%', width: '20%' }}>Account Info</h2>
      <div style={{ width: '20%' }}>
        <label># Data Fields to display:</label>
        <select value={numColumns} onChange={handleNumColumnsChange}>
          <option value="10">10</option>
          <option value="0">All</option>
          {columns.slice(6).map((column, index) => (
            <option key={index} value={index + 1}>{index + 1}</option>
          ))}
        </select>
      </div>

      <div>
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <button onClick={handleViewBets}>Betting History</button>
        </div>
        {editMode ? (
          <div>
            <h3>Edit User ID# {selectedUser.id}, {user.full_name}</h3>
            <form onSubmit={handleFormSubmit}>
              {Object.entries(formData).slice(6, 6 + numColumns).map(([key, value], index) => (
                <div key={key + index}>
                  <label>
                    <FormatKey keyText={key} />:
                  </label>
                  <input type="text" name={key} value={value} onChange={handleInputChange} />
                </div>
              ))}
              <button type="submit">Update</button>
              <button type="button" onClick={handleClearForm}>Cancel</button>
            </form>
          </div>
        ) : (
          <div>
            <button onClick={handleToggleEditMode}>Edit</button>
            <h3>Details: {user.full_name}</h3>
            <table>
              <tbody>
                {Object.entries(selectedUser).slice(6, 6 + numColumns).map(([key, value], index) => (
                  <tr key={key + index}>
                    <td>
                      <FormatKey keyText={key} />
                    </td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
