import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import "./index.css";
import { RaceProvider } from "./Components/Pages/RaceContext";
import AuthProvider from "./Components/Modules/Auth/AuthContext";
import PreloadedImages from "./Components/Modules/PreLoadedImages";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <RaceProvider>
        <PreloadedImages>
          {(startImage, gateImage, trackImage) => (
            <App startImage={startImage} gateImage={gateImage} trackImage={trackImage} />
          )}
        </PreloadedImages>
      </RaceProvider>
    </AuthProvider>
  </QueryClientProvider>
);


// // PRE LOAD LARGE IMAGES
// import React from "react";
// import ReactDOM from "react-dom/client";
// import { HashRouter } from "react-router-dom";
// import "./index.css";
// import PreloadedImages from "./components/Modules/PreLoadedImages"; // Import the PreloadedImages component
// import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

//   <PreloadedImages>
//     {(startImage, gateImage, trackImage) => (
//       <HashRouter>
//         <App startImage={startImage} gateImage={gateImage} trackImage={trackImage} />
//       </HashRouter>
//     )}
//   </PreloadedImages>
// );
