

// import React, { useState, useEffect, useContext } from "react";
// import "../../Styles/Clock.css";
// import { RaceContext } from '../../Pages/RaceContext';
// import CalculateStartTimes from "./CalculateStartTimes";

// function Clock({ showCountdown }) {
//   const { trackId, postTime } = useContext(RaceContext);
//   const [countdown, setCountdown] = useState(0);
//   const [targetTime, setTargetTime] = useState(null);

//   const initializeClock = async () => {
//     if (trackId) {
//       try {
//         const nextRaceTime = await CalculateStartTimes(trackId);
//         setTargetTime(new Date(nextRaceTime));
//       } catch (error) {
//         console.error("Error initializing clock:", error);
//       }
//     }
//   };

//   useEffect(() => {
//     initializeClock();
//   }, [trackId]);

//   useEffect(() => {
//     const updateCountdown = () => {
//       const now = new Date();
//       let nextPostTime = postTime;

//       if (postTime instanceof Date) {
//         if (postTime <= now) {
//           // If postTime is in the past, set it to the next occurrence within the next 24 hours
//           nextPostTime = new Date(postTime);
//           nextPostTime.setDate(now.getDate() + 1);
//         }

//         const remainingTime = nextPostTime.getTime() - now.getTime();
//         setCountdown(remainingTime > 0 ? remainingTime : 0);
//       }
//     };

//     const intervalId = setInterval(updateCountdown, 1000);

//     return () => clearInterval(intervalId);
//   }, [postTime]);

//   useEffect(() => {
//     const checkAndReinitialize = () => {
//       if (!targetTime || isNaN(targetTime.getTime())) {
//         console.warn("Invalid targetTime, reinitializing clock...");
//         initializeClock();
//       }
//     };

//     const timerId = setTimeout(checkAndReinitialize, 500);

//     return () => clearTimeout(timerId);
//   }, [targetTime]);

//   const totalSeconds = Math.floor(countdown / 1000);
//   const hours = Math.floor(totalSeconds / 3600);
//   const minutes = Math.floor((totalSeconds % 3600) / 60);
//   const seconds = totalSeconds % 60;

//   return (
//     <div className="clock-container">
//       <p id="time">Time: {new Date().toLocaleTimeString()}</p>
//       <p id="start-time">Start time: {targetTime ? targetTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "Loading..."}</p>
//       <p id="post-time">Post time: {postTime ? postTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "Loading..."}</p>
//       {showCountdown && (
//         <p id="race-begins">
//           Race begins: {hours > 0 ? `${hours} hrs ` : ""}
//           {minutes} mins {seconds} secs
//         </p>
//       )}
//     </div>
//   );
// }

// export default Clock;





import React, { useState, useEffect, useContext } from "react";
import "../../Styles/Clock.css";
import { RaceContext } from '../../Pages/RaceContext';
import CalculateStartTimes from "./CalculateStartTimes";

function Clock({ showCountdown }) {
  const { trackId, postTime } = useContext(RaceContext);
  const [countdown, setCountdown] = useState(0);
  const [targetTime, setTargetTime] = useState(null);

  const initializeClock = async () => {
    if (trackId) {
      try {
        const nextRaceTime = await CalculateStartTimes(trackId);
        setTargetTime(new Date(nextRaceTime));
      } catch (error) {
        console.error("Error initializing clock:", error);
      }
    }
  };

  useEffect(() => {
    // Reset the states
    setCountdown(0);
    setTargetTime(null);

    initializeClock();
  }, [trackId]);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      let nextPostTime = postTime;

      if (postTime instanceof Date) {
        if (postTime <= now) {
          // If postTime is in the past, set it to the next occurrence within the next 24 hours
          nextPostTime = new Date(postTime);
          nextPostTime.setDate(now.getDate() + 1);
        }

        const remainingTime = nextPostTime.getTime() - now.getTime();
        setCountdown(remainingTime > 0 ? remainingTime : 0);
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [postTime]);

  useEffect(() => {
    const checkAndReinitialize = () => {
      if (!targetTime || isNaN(targetTime.getTime())) {
        console.warn("Invalid targetTime, reinitializing clock...");
        initializeClock();
      }
    };

    const timerId = setTimeout(checkAndReinitialize, 500);

    return () => clearTimeout(timerId);
  }, [targetTime]);

  const totalSeconds = Math.floor(countdown / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return (
    <div className="clock-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <div style={{ flex: '1 1  1%' }}>
        <p id="time">Time: {new Date().toLocaleTimeString()}</p>
        <p id="start-time">Start time: {targetTime ? targetTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "Loading..."}</p>
      </div>
      <div style={{ flex: '1 1  60%', textAlign: 'right' }}>
        <p id="post-time">Post time: {postTime ? postTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) : "Loading..."}</p>
        {showCountdown && (
          <p id="race-begins">
            Race begins: {hours > 0 ? `${hours} hrs ` : ""}
            {minutes} mins {seconds} secs
          </p>
        )}
      </div>
    </div>
  );
}

export default Clock;