import React from 'react';

function BetPayouts({ bets }) {
  return (
    <div>
      <h2>Bet Payouts</h2>
      {bets.map((bet, index) => (
        <div key={index}>
          <p>Bet Type: {bet.betType}</p>
          <p>Amount Bet: ${bet.bet_amount}</p>
          <p>Potential Payout: ${bet.payout}</p>
        </div>
      ))}
    </div>
  );
}

export default BetPayouts;
