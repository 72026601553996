// Data is from Freerandomdata.js
import { createContext, useEffect, useState } from 'react';

export const FreeContext = createContext();

export const FreeProvider = ({ children }) => {
  const [randomData, setRandomData] = useState(() => {
    const storedData = sessionStorage.getItem('randomData');
    const initialData = storedData
      ? JSON.parse(storedData)
      : {
          horses: [],
          jockeys: [],
          trainers: [],
          owners: [],
          tracks: [],
          races: [],
        };

    // console.log('Input - initialData:', initialData); // Log the initialData input

    return initialData;
  });

  useEffect(() => {
    sessionStorage.setItem('randomData', JSON.stringify(randomData));
    // console.log('Output - randomData:', randomData); // Log the updated randomData output
  }, [randomData]);

  return (
    <FreeContext.Provider value={{ randomData, setRandomData }}>
      {children}
    </FreeContext.Provider>
  );
};
