// TODO == REFACTOR CODE TO USE ONE FORM FOR OWNERS/OWNERS/TRAINERS, ETC
//  AND IN THIS FORM PASS A ROW OF DATA TO THE /RECORD PAGE INSTEAD OF 
// SENDING THE ID AND DOING ANOTHER DATABASE GET FUNCTIOIN
//===========

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/dataTables.css';
import PageOwner from './PageOwner';

function Owner() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [Pagesize, setPagesize] = useState(5);
  const [sortConfig, setSortConfig] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [table, setTable] =useState('owners');
  const navigate = useNavigate();


  function handleSetTable(props) {
    setTable('owners');
    }
  
  console.log(table);

  useEffect(() => {
    axios
      .get(' https://hotponies.com/owners')
      .then(response => {
        setData(response.data);
                const allColumns = Object.keys(response.data[0]);
      const initialColumns = allColumns.slice(0, 10);
      setColumns(allColumns);
      setSelectedColumns(initialColumns);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

   function handleColumnSelect(event) {
    const selected = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedColumns(selected);
  }

  function handleOwnerSelect(e) {
    const options = e.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedOwners(selected);
  }

  function handlePagesizeSelect(e) {
    const Pagesize = parseInt(e.target.value);
    setPagesize(Pagesize);
  }

  function handleSort(key) {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  function sortData(data) {
    if (!sortConfig) {
      return data;
    }

    const sortedData = [...data];
    const { key, direction } = sortConfig;

    sortedData.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    return sortedData;
  }

  function filterData(data) {
    let filteredData = data;

    if (selectedOwners.length > 0) {
      filteredData = filteredData.filter(row => selectedOwners.includes(row.owner_name));
    }

    if (searchTerm) {
      filteredData = filteredData.filter(row => row.owner_name.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    return filteredData;
  }

  const totalRows = filterData(data).length;
  const totalPages = Math.ceil(totalRows / Pagesize);
  const startIndex = (currentPage - 1) * Pagesize;
  const endIndex = startIndex + Pagesize;
  const currentPageData = filterData(sortData(data)).slice(startIndex, endIndex);

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
    }
    function handleSearch(e) {
    setSearchTerm(e.target.value);
    }
      //  === TODO === NEED TO STYLE SEARCH, ITEMS, COLUMNS, OWNERS  === TODO === 
    return (
    <div>
    <h1>Owners</h1>
    <div>
    <label htmlFor="search">Search:</label>
    <input type="text" id="search" value={searchTerm} onChange={handleSearch} style={{"display": "flex", "maxWidth": "30%"}}/>
    {/* </div>
    <div> */}
    <label htmlFor="Pagesize">Items/Page:</label>
    <select id="Pagesize" value={Pagesize} onChange={handlePagesizeSelect} style={{"maxWidth": "30%", "display": "flex"}}>
    <option value="5">5</option>
    <option value="10">10</option>
    <option value="15">15</option>
    </select>
    {/* </div>
    <div> */}
    <label htmlFor="column-select" >Columns:</label>
    <select id="column-select" multiple={true} onChange={handleColumnSelect} style={{"maxWidth": "30%", "display": "flex", "align-content": "flex-start"}}>
    {columns.map(column => (
    <option key={column} value={column} selected={selectedColumns.includes(column)}>
    {column}
    </option>
    ))}
    </select>
    {/* </div>
    <div> */}
    <label htmlFor="owner-select">Owners:</label>
    <select id="owner-select" multiple={true} onChange={handleOwnerSelect} style={{"maxWidth": "30%", "display": "flex", "align-content": "flex-start"}}>
    {data.map(row => (
    <option key={row.id} value={row.owner_name} selected={selectedOwners.includes(row.owner_name)}>
    {row.owner_name}
    </option>
    ))}
    </select>
    </div>
    <table className="data-table">
    <thead>
    <tr>
    {selectedColumns.map(column => (
    <th key={column} onClick={() => handleSort(column)}>
    {column} {sortConfig && sortConfig.key === column && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
    </th>
    ))}
    </tr>
    </thead>

<tbody>
 {currentPageData.map(row => (
 <tr key={row.id} onClick={() =>   navigate(`/page-owner/${row.id}`)}>
 {selectedColumns.map(col => (
 <td key={col}>{row[col]}</td>
 ))}   

 </tr>
 
 ))}

 </tbody>   
    </table>
    <div>
    <p>
    Showing {startIndex + 1}-{Math.min(endIndex, totalRows)} of {totalRows} rows
    </p>
    <nav>
    <ul className="pagination">
    {[...Array(totalPages)].map((_, index) => (
    <li key={index} className={`page-item ${index + 1 === currentPage ? 'active' : ''}`}>
    <button className="page-link" onClick={() => handlePageChange(index + 1)}>
    {index + 1}
    </button>
    </li>
    ))}
    </ul>
    </nav>
    </div>
    {/* <button onClick={() => navigate('/owners/new')}>Add Owner</button> */}

    </div>
    );
    }
    
    export default Owner;
    