
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../Styles/Hamburger.css';

const Hamburger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setActiveMenu(null);
  };

  const toggleSubMenu = (menuIndex) => {
    setActiveMenu(menuIndex);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setActiveMenu(null);
  };

  return (
    <>
      <div className="hamburger-wrapper" style={{ marginTop: '10vh', marginBottom: '2px' }}>
        <div className={`hamburger-menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
        </div>

        {isOpen && (
          <ul className={`hamburger-menu ${isOpen ? 'open' : 'hidden'}`} onMouseLeave={closeMenu}>
            <li className={`hamburger-list ${activeMenu === 1 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(1)}>
                Home
              </div>
            </li>

            <li className={`hamburger-list ${activeMenu === 2 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(2)}>
                PROGRAMS
              </div>
              {activeMenu === 2 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/program-card" active="active">
                      Program Card
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/leaderboard" active="active">
                      Leaderboard
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/race-screens" active="active">
                      Free Races
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className={`hamburger-list ${activeMenu === 3 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(3)}>
                STATS & PROFILES
              </div>
              {activeMenu === 3 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/horse" active="active">
                      Horse
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/jockey" active="active">
                      Jockey
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/trainer" active="active">
                      Trainer
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/race-track" active="active">
                      Track
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/races" active="active">
                      Races
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className={`hamburger-list ${activeMenu === 4 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(4)}>
                RACING
              </div>
              {activeMenu === 4 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/current-races" active="active">
                      Race Screens
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/race-results" active="active">
                      Race Results
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/gear" active="active">
                      Race Gear
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className={`hamburger-list ${activeMenu === 5 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(5)}>
                BETTING
              </div>
              {activeMenu === 5 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/bet-types" active="active">
                      Bet Types
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/bet-payouts" active="active">
                      Payouts
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/bet-odds" active="active">
                      ODDS
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className={`hamburger-list ${activeMenu === 6 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(6)}>
                AUCTIONS
              </div>
              {activeMenu === 6 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/auction" active="active">
                      Auction
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/dates" active="active">
                      Dates
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/breeds" active="active">
                      Breeds
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/public" active="active">
                      Public
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/private" active="active">
                      Private
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/bidding" active="active">
                      Bidding
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/current-highest-bid" active="active">
                      High Bid
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/auction-results" active="active">
                      Results
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/facilities" active="active">
                      Facilities
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/staff" active="active">
                      Staff
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/sales-contracts" active="active">
                      Sales Contracts
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/breeding-contracts" active="active">
                      Breeding Contracts
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className={`hamburger-list ${activeMenu === 7 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(7)}>
                SETTINGS
              </div>
              {activeMenu === 7 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/user-settings" active="active">
                      User Settings
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/account-settings" active="active">
                      Account Settings
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className={`hamburger-list ${activeMenu === 8 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(8)}>
                HELP & SUPPORT
              </div>
              {activeMenu === 8 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/faq" active="active">
                      FAQ
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/contact-us" active="active">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li className={`hamburger-list ${activeMenu === 9 ? 'active' : ''}`}>
              <div className="hamburger-list-toggle" onClick={() => toggleSubMenu(9)}>
                SUBMIT
              </div>
              {activeMenu === 9 && (
                <ul className="hamburger-submenu">
                  <li className="hamburger-submenu-item">
                    <NavLink to="/submit-program" active="active">
                      Program
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/submit-race" active="active">
                      Race
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/submit-bug" active="active">
                      Bug
                    </NavLink>
                  </li>
                  <li className="hamburger-submenu-item">
                    <NavLink to="/submit-feedback" active="active">
                      Feedback
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default Hamburger;
