

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import FormatKey from "../Modules/FormatKey";
// import { RaceContext } from "../Pages/RaceContext";

// // Modal Component Definition

// const Modal = ({ visible, onClose, title, children }) => {
//   if (!visible) return null;
//   return (
//     <div style={{
//       position: 'fixed',
//       top: '15%',
//       left: '15%',
//       right: '15%',
//       bottom: '12%',
//       backgroundColor: 'white',
//       padding: '20px',
//       boxShadow: '0 8px 10px 20px rgba(0, 0, 0, 0.2)', // Updated shadow for more emphasis
//       zIndex: 1000,
//       borderRadius: '8px', // Optional: adding rounded corners for a more modern look
//     }}>
//       <button
//         onClick={onClose}
//         style={{
//           position: 'absolute',
//           top: '2%',
//           right: '2%',
//           backgroundColor: '#007BFF',
//           color: 'white',
//           border: 'none',
//           borderRadius: '4px',
//           padding: '10px 20px',
//           cursor: 'pointer',
//         }}
//       >
//         Close
//       </button>
//       <h3 style={{ fontSize: '16pt', marginBottom: '20px' }}>{title}</h3>
//       <div style={{ 
//         fontSize: '12pt', 
//         height: 'calc(100% - 60px)', // Adjust height to account for padding and button
//         overflowY: 'auto', 
//         marginTop: '40px'  // Push content down to not overlap with button
//       }}>
//         {children}
//       </div>
//     </div>
//   );
// };

// const AdvancedBetRaces = () => {
//   const [loadingRaceDetails, setLoadingRaceDetails] = useState(false);
//   const location = useLocation();
//   const { selectedRaces, trackName, selectedBet } = location.state || {}; // Include betType
//   const navigate = useNavigate();
//   const [races, setRaces] = useState([]);
//   const [selectedRaceId, setSelectedRaceId] = useState(null);
//   const [selectedRace, setSelectedRace] = useState(null);
//   const [horseData, setHorseData] = useState([]);
//   const [numColumns, setNumColumns] = useState(6);
//   const [selectedHorses, setSelectedHorses] = useState({});
//   const [betAmount, setBetAmount] = useState("");
//   const { setCurrentRaceId, setNumHorses, setRace } = useContext(RaceContext);

//   const [modalVisible, setModalVisible] = useState(false);
//   const [modalData, setModalData] = useState(null);
//   const [modalTitle, setModalTitle] = useState("");

//   useEffect(() => {
//     const fetchSelectedRaces = async () => {
//       try {
//         console.log("Selected races:", selectedRaces);
//         const racePromises = selectedRaces.map(async (raceId) => {
//           try {
//             const raceResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
//             console.log(`Fetched race ${raceId}:`, raceResponse.data);
//             return raceResponse.data;
//           } catch (error) {
//             console.error(`Error fetching race with ID ${raceId}:`, error);
//             return null;
//           }
//         });
//         const fetchedRaces = await Promise.all(racePromises);
//         setRaces(fetchedRaces.filter(race => race !== null));
//       } catch (error) {
//         console.error("Error fetching selected races:", error);
//       }
//     };

//     fetchSelectedRaces();
//   }, [selectedRaces]);

//   const ensureRaceInCurrentRaces = async (raceId, raceData) => {
//     try {
//       console.log(`Ensuring race in current races: ${raceId}`, raceData);
//       const currentRaceResponse = await axios.get(`https://hotponies.com/current_races/race_id/${raceId}`);
//       console.log("Current race response:", currentRaceResponse.data);
//       return currentRaceResponse.data;
//     } catch (error) {
//       console.log(`Error fetching current race with ID ${raceId}:`, error);
//       if (error.response && error.response.status === 404) {
//         const newRace = {
//           race_id: raceId,
//           location: raceData.location,
//           race_date: raceData.race_date,
//           post_time: raceData.starting_time,
//           num_horses: raceData.num_horses,
//           track_id: raceData.track_id,
//           miles: raceData.miles,
//           furlongs: raceData.furlongs,
//           meters: raceData.meters,
//           weather: raceData.weather,
//           surface_type: raceData.surface_type,
//           track_condition: raceData.track_condition,
//           purse: raceData.purse
//         };
//         console.log("Creating new race entry:", newRace);
//         const createResponse = await axios.post(`https://hotponies.com/current_races`, newRace);
//         console.log("New race created:", createResponse.data);
//         return createResponse.data;
//       } else {
//         throw error;
//       }
//     }
//   };

//   const handleGoBack = () => {
//     navigate(-1);
//   };

//   const handleRaceSelect = async (event) => {
//     const raceId = event.target.value;
//     console.log("Selected race ID:", raceId);
//     setSelectedRaceId(raceId);
//     setLoadingRaceDetails(true);
//     try {
//       const raceResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
//       console.log(`Fetched race details for race ID ${raceId}:`, raceResponse.data);
//       setSelectedRace(raceResponse.data);

//       const currentRace = await ensureRaceInCurrentRaces(raceId, raceResponse.data);

//       const numHorses = currentRace.num_horses;
//       console.log(`Number of horses in current race ${raceId}:`, numHorses);
//       const horseKeys = Object.keys(currentRace).filter(key => key.startsWith('horse_id')).slice(0, numHorses);
//       const jockeyKeys = Object.keys(currentRace).filter(key => key.startsWith('jockey_id')).slice(0, numHorses);

//       let horseAndJockeyData = [];

//       for (let i = 0; i < horseKeys.length; i++) {
//         let horseId = currentRace[horseKeys[i]];
//         let jockeyId = currentRace[jockeyKeys[i]];

//         if (!horseId || !jockeyId) {
//           console.log(`Missing horse or jockey data for race ${raceId}, fetching random data...`);
//           const randomHorseResponse = await axios.get(`https://hotponies.com/random-records/horses/1`);
//           const randomJockeyResponse = await axios.get(`https://hotponies.com/random-records/jockeys/1`);
//           const newHorse = randomHorseResponse.data[0];
//           const newJockey = randomJockeyResponse.data[0];

//           horseId = newHorse.id;
//           jockeyId = newJockey.id;

//           const updateData = {};
//           updateData[horseKeys[i]] = horseId;
//           updateData[jockeyKeys[i]] = jockeyId;

//           await axios.patch(`https://hotponies.com/current_races/${currentRace.id}`, updateData);
//           console.log(`Updated current race ${currentRace.id} with new horse and jockey IDs:`, updateData);
//         }

//         const horseResponse = await axios.get(`https://hotponies.com/horses/${horseId}`);
//         const jockeyResponse = await axios.get(`https://hotponies.com/jockeys/${jockeyId}`);
//         console.log(`Fetched horse ${horseId} and jockey ${jockeyId} details:`, {
//           horse: horseResponse.data,
//           jockey: jockeyResponse.data,
//         });
//         horseAndJockeyData.push({
//           horse: horseResponse.data,
//           jockey: jockeyResponse.data,
//         });
//       }

//       setHorseData(horseAndJockeyData);
//     } catch (error) {
//       console.error(`Error fetching race with ID ${raceId}:`, error);
//       setSelectedRace(null);
//     } finally {
//       setLoadingRaceDetails(false);
//     }
//   };

//   const handleNumColumnsChange = (event) => {
//     const num = parseInt(event.target.value, 10);
//     console.log("Number of columns to display:", num);
//     setNumColumns(num);
//   };

//   const handleCheckboxChange = (horseId, raceId) => {
//     console.log(`Selected horse ID ${horseId} for race ID ${raceId}`);
//     setSelectedHorses(prevState => ({
//       ...prevState,
//       [raceId]: horseId
//     }));
//   };

//   const handleBetAmountChange = (event) => {
//     console.log("Bet amount:", event.target.value);
//     setBetAmount(event.target.value);
//   };

//   const handleFinalizeBet = async () => {
//     const selectedHorsesArray = Object.entries(selectedHorses).map(([raceId, horseId]) => ({
//       raceId,
//       horseId,
//     }));
//     console.log("Selected horses array:", selectedHorsesArray);

//     if (!betAmount || selectedHorsesArray.length < selectedBet) {
//       alert("Please select a horse from each required race and enter a bet amount.");
//       return;
//     }

//     try {
//       const userId = localStorage.getItem("userId");
//       const betData = {
//         bet_amount: betAmount,
//         bet_total: betAmount * selectedHorsesArray.length,
//         bet_type: selectedBet,
//         current_race_id: selectedRaceId,
//         track_name: trackName,
//         bet_num_horses: selectedHorsesArray.length,
//         user_id: userId,
//         horse_order: selectedHorsesArray.map((horse, index) => ({ ...horse, order: index + 1 })),
//       };

//       selectedHorsesArray.forEach((horse, index) => {
//         betData[`horse_id${index + 1}`] = horse.horseId;
//       });

//       console.log("Submitting bet data:", betData);
//       const response = await axios.post(`https://hotponies.com/bets`, betData);
//       console.log("Bet finalized successfully:", response.data);
//       alert("Bet finalized successfully!");
//     } catch (error) {
//       console.error("Error finalizing bet:", error);
//       alert("Error finalizing bet. Please try again.");
//     }
//   };

//   const handleOpenModal = (title, data) => {
//     setModalTitle(title);
//     setModalData(data);
//     setModalVisible(true);
//   };

//   const handleCloseModal = () => {
//     setModalVisible(false);
//     setModalData(null);
//     setModalTitle("");
//   };

//   const formatDataInColumns = (data, numColumns) => {
//     if (!data) return [];
//     const keys = Object.keys(data);
//     const numRows = Math.ceil(keys.length / numColumns);
//     const rows = [];
//     for (let i = 0; i < numRows; i++) {
//       rows.push(keys.slice(i * numColumns, i * numColumns + numColumns));
//     }
//     return rows;
//   };

//   return (
//     <div>
//       <button onClick={handleGoBack} style={{ marginTop: "4vh" }}>
//         Go Back
//       </button>
//       <h1>Select A Horse From Each Race</h1>
//       <h2>Track: {trackName}</h2>
      
//       <div>
//         <label htmlFor="raceSelect">Select a race:</label>
//         <select
//           id="raceSelect"
//           value={selectedRaceId || ""}
//           onChange={handleRaceSelect}
//           style={{ marginLeft: '2.7%', width: 'auto' }}
//         >
//           <option value="" disabled>Select a race</option>
//           {races.map((race) => (
//             <option key={race.id} value={race.id}>
//               {`Race #: ${race.id} -- Race: ${race.race_name}`}
//             </option>
//           ))}
//         </select>
//       </div>

//       <label>
//         Number of columns to display:
//         <select
//           value={numColumns}
//           onChange={handleNumColumnsChange}
//           style={{ marginLeft: '1%', width: "auto" }}
//         >
//           {Object.keys(races[0] || {}).map((_, index) => (
//             <option key={index} value={index + 1}>
//               {index + 1}
//             </option>
//           ))}
//         </select>
//       </label>

//       {loadingRaceDetails ? (
//         <p>Loading race details...</p>
//       ) : selectedRace ? (
//         <div style={{ marginTop: '20px' }}>
//           <h3>Race Details:</h3>
//           <table>
//             <thead>
//               <tr>
//                 {Object.keys(selectedRace)
//                   .slice(0, numColumns)
//                   .map((key) => (
//                     <th key={key}><FormatKey keyText={key} /></th>
//                   ))}
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 {Object.keys(selectedRace)
//                   .slice(0, numColumns)
//                   .map((key) => (
//                     <td key={key}>{selectedRace[key]}</td>
//                   ))}
//               </tr>
//             </tbody>
//           </table>

//           {horseData.length > 0 && (
//             <div style={{ marginTop: '20px' }}>
//               <h3>Horse and Jockey Details:</h3>
//               <table>
//                 <thead>
//                   <tr>
//                     {Object.keys(horseData[0].horse).slice(0, 4).map((key, index) => (
//                       <th key={index}>{FormatKey({ keyText: key })}</th>
//                     ))}
//                     {Object.keys(horseData[0].jockey).slice(0, 4).map((key, index) => (
//                       <th key={index}>{FormatKey({ keyText: key })}</th>
//                     ))}
//                     <th>Select</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {horseData.map((data, index) => (
//                     <tr key={index}>
//                       {Object.keys(data.horse).slice(0, 4).map((key, keyIndex) => (
//                         <td 
//                           key={keyIndex} 
//                           style={{ cursor: 'pointer' }} 
//                           onClick={() => handleOpenModal('Horse Details', data.horse)}
//                         >
//                           {data.horse[key]}
//                         </td>
//                       ))}
//                       {Object.keys(data.jockey).slice(0, 4).map((key, keyIndex) => (
//                         <td 
//                           key={keyIndex} 
//                           style={{ cursor: 'pointer' }} 
//                           onClick={() => handleOpenModal('Jockey Details', data.jockey)}
//                         >
//                           {data.jockey[key]}
//                         </td>
//                       ))}
//                       <td>
//                         <input
//                           type="checkbox"
//                           checked={selectedHorses[selectedRace.id] === data.horse.id}
//                           onChange={() => handleCheckboxChange(data.horse.id, selectedRace.id)}
//                         />
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//         </div>
//       ) : (
//         <p>Please select a race to see the details.</p>
//       )}

//       <div style={{ 
//         marginTop: '4vh', 
//         position: 'fixed', 
//         right: '10vw', 
//         top: '20%', 
//         backgroundColor: 'lightblue', 
//         padding: '20px', 
//         borderRadius: '8px', 
//         boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
//       }}>

// <div style={{ marginTop: '20px' }}>
//         <label>
//           Bet Amount per Horse:
//           <input
//             type="number"
//             value={betAmount}
//             onChange={handleBetAmountChange}
//             style={{ marginLeft: '1%', width: '16%' }}
//           />
//         </label>
        
//       </div>
//         <h3 style={{ 
//           borderBottom: '2px solid darkblue', 
//           paddingBottom: '10px', 
//           marginBottom: '10px' 
//         }}>{selectedBet} --- Selected Horses</h3>
//         <div>
//           {Object.entries(selectedHorses).map(([raceId, horseId]) => {
//             const horse = horseData.find(data => data.horse.id === horseId)?.horse;
//             return (
//               <div key={raceId} style={{ 
//                 padding: '10px', 
//                 backgroundColor: 'white', 
//                 borderRadius: '4px', 
//                 marginBottom: '10px',
//                 boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
//               }}>
//                 <p style={{ 
//                   margin: '0', 
//                   fontWeight: 'bold' 
//                 }}>Race {raceId}: <span style={{ 
//                   color: 'darkblue' 
//                 }}>{horse ? horse.horse_name : `Horse ${horseId}`}</span></p>
//               </div>
//             );
//           })}
//         </div>
//         <div style={{ marginTop: '20px', fontWeight: 'bold', fontSize: '16pt' }}>
//           Total Bet Amount: ${betAmount * Object.keys(selectedHorses).length}
//         </div>
//         <button onClick={handleFinalizeBet} style={{ marginLeft: '30%' }}>
//           Finalize Bet
//         </button>
//       </div>

//       <Modal
//         visible={modalVisible}
//         onClose={handleCloseModal}
//         title={modalTitle}
//       >
//         <div style={{ display: 'flex', flexWrap: 'wrap' }}>
//           {modalData && formatDataInColumns(modalData, numColumns).map((row, rowIndex) => (
//             <div key={rowIndex} style={{ flex: '1 1 30%', padding: '10px', boxSizing: 'border-box' }}>
//               {row.map(key => (
//                 <div key={key}>
//                   <strong>{FormatKey({ keyText: key })}:</strong> {modalData[key]}
//                 </div>
//               ))}
//             </div>
//           ))}
//         </div>
//       </Modal>
//     </div>
//   );
// };

// export default AdvancedBetRaces;



import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import FormatKey from "../Modules/FormatKey";
import { RaceContext } from "../Pages/RaceContext";

// Modal Component Definition

const Modal = ({ visible, onClose, title, children }) => {
  if (!visible) return null;
  return (
    <div style={{
      position: 'fixed',
      top: '15%',
      left: '15%',
      right: '15%',
      bottom: '12%',
      backgroundColor: 'white',
      padding: '20px',
      boxShadow: '0 8px 10px 20px rgba(0, 0, 0, 0.2)', // Updated shadow for more emphasis
      zIndex: 1000,
      borderRadius: '8px', // Optional: adding rounded corners for a more modern look
    }}>
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '2%',
          right: '2%',
          backgroundColor: '#007BFF',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
      >
        Close
      </button>
      <h3 style={{ fontSize: '16pt', marginBottom: '20px' }}>{title}</h3>
      <div style={{ 
        fontSize: '12pt', 
        height: 'calc(100% - 60px)', // Adjust height to account for padding and button
        overflowY: 'auto', 
        marginTop: '40px'  // Push content down to not overlap with button
      }}>
        {children}
      </div>
    </div>
  );
};

const AdvancedBetRaces = () => {
  const [loadingRaceDetails, setLoadingRaceDetails] = useState(false);
  const location = useLocation();
  const { selectedRaces, trackName, selectedBet } = location.state || {}; // Include betType
  const navigate = useNavigate();
  const [races, setRaces] = useState([]);
  const [selectedRaceId, setSelectedRaceId] = useState(null);
  const [selectedRace, setSelectedRace] = useState(null);
  const [horseData, setHorseData] = useState([]);
  const [numColumns, setNumColumns] = useState(6);
  const [selectedHorses, setSelectedHorses] = useState({});
  const [betAmount, setBetAmount] = useState("");
  const { setCurrentRaceId, setNumHorses, setRace } = useContext(RaceContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const betTypeLimits = {
    "Pick 3": 3,
    "Pick 4": 4,
    "Pick 5": 5,
    "Pick 6": 6,
    "Pick 7": 7,
    "Pick 8": 8,
    "Pick 9": 9,
    "Pick 10": 10,
    "Daily Double": 2,
    // Add other bet types with their limits if needed
  };

  const requiredSelections = betTypeLimits[selectedBet] || 0;

  useEffect(() => {
    const fetchSelectedRaces = async () => {
      try {
        console.log("Selected races:", selectedRaces);
        const racePromises = selectedRaces.map(async (raceId) => {
          try {
            const raceResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
            console.log(`Fetched race ${raceId}:`, raceResponse.data);
            return raceResponse.data;
          } catch (error) {
            console.error(`Error fetching race with ID ${raceId}:`, error);
            return null;
          }
        });
        const fetchedRaces = await Promise.all(racePromises);
        setRaces(fetchedRaces.filter(race => race !== null));
      } catch (error) {
        console.error("Error fetching selected races:", error);
      }
    };

    fetchSelectedRaces();
  }, [selectedRaces]);

  const ensureRaceInCurrentRaces = async (raceId, raceData) => {
    try {
      console.log(`Ensuring race in current races: ${raceId}`, raceData);
      const currentRaceResponse = await axios.get(`https://hotponies.com/current_races/race_id/${raceId}`);
      console.log("Current race response:", currentRaceResponse.data);
      return currentRaceResponse.data;
    } catch (error) {
      console.log(`Error fetching current race with ID ${raceId}:`, error);
      if (error.response && error.response.status === 404) {
        const newRace = {
          race_id: raceId,
          location: raceData.location,
          race_date: raceData.race_date,
          post_time: raceData.starting_time,
          num_horses: raceData.num_horses,
          track_id: raceData.track_id,
          miles: raceData.miles,
          furlongs: raceData.furlongs,
          meters: raceData.meters,
          weather: raceData.weather,
          surface_type: raceData.surface_type,
          track_condition: raceData.track_condition,
          purse: raceData.purse
        };
        console.log("Creating new race entry:", newRace);
        const createResponse = await axios.post(`https://hotponies.com/current_races`, newRace);
        console.log("New race created:", createResponse.data);
        return createResponse.data;
      } else {
        throw error;
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleRaceSelect = async (event) => {
    const raceId = event.target.value;
    console.log("Selected race ID:", raceId);
    setSelectedRaceId(raceId);
    setLoadingRaceDetails(true);
    try {
      const raceResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
      console.log(`Fetched race details for race ID ${raceId}:`, raceResponse.data);
      setSelectedRace(raceResponse.data);

      const currentRace = await ensureRaceInCurrentRaces(raceId, raceResponse.data);

      const numHorses = currentRace.num_horses;
      console.log(`Number of horses in current race ${raceId}:`, numHorses);
      const horseKeys = Object.keys(currentRace).filter(key => key.startsWith('horse_id')).slice(0, numHorses);
      const jockeyKeys = Object.keys(currentRace).filter(key => key.startsWith('jockey_id')).slice(0, numHorses);

      let horseAndJockeyData = [];

      for (let i = 0; i < horseKeys.length; i++) {
        let horseId = currentRace[horseKeys[i]];
        let jockeyId = currentRace[jockeyKeys[i]];

        if (!horseId || !jockeyId) {
          console.log(`Missing horse or jockey data for race ${raceId}, fetching random data...`);
          const randomHorseResponse = await axios.get(`https://hotponies.com/random-records/horses/1`);
          const randomJockeyResponse = await axios.get(`https://hotponies.com/random-records/jockeys/1`);
          const newHorse = randomHorseResponse.data[0];
          const newJockey = randomJockeyResponse.data[0];

          horseId = newHorse.id;
          jockeyId = newJockey.id;

          const updateData = {};
          updateData[horseKeys[i]] = horseId;
          updateData[jockeyKeys[i]] = jockeyId;

          await axios.patch(`https://hotponies.com/current_races/${currentRace.id}`, updateData);
          console.log(`Updated current race ${currentRace.id} with new horse and jockey IDs:`, updateData);
        }

        const horseResponse = await axios.get(`https://hotponies.com/horses/${horseId}`);
        const jockeyResponse = await axios.get(`https://hotponies.com/jockeys/${jockeyId}`);
        console.log(`Fetched horse ${horseId} and jockey ${jockeyId} details:`, {
          horse: horseResponse.data,
          jockey: jockeyResponse.data,
        });
        horseAndJockeyData.push({
          horse: horseResponse.data,
          jockey: jockeyResponse.data,
        });
      }

      setHorseData(horseAndJockeyData);
    } catch (error) {
      console.error(`Error fetching race with ID ${raceId}:`, error);
      setSelectedRace(null);
    } finally {
      setLoadingRaceDetails(false);
    }
  };

  const handleNumColumnsChange = (event) => {
    const num = parseInt(event.target.value, 10);
    console.log("Number of columns to display:", num);
    setNumColumns(num);
  };

  const handleCheckboxChange = (horseId, raceId) => {
    console.log(`Selected horse ID ${horseId} for race ID ${raceId}`);
    setSelectedHorses(prevState => ({
      ...prevState,
      [raceId]: horseId
    }));
  };

  const handleBetAmountChange = (event) => {
    console.log("Bet amount:", event.target.value);
    setBetAmount(event.target.value);
  };

  const handleFinalizeBet = async () => {
    const selectedHorsesArray = Object.entries(selectedHorses).map(([raceId, horseId]) => ({
      raceId,
      horseId,
    }));
    console.log("Selected horses array:", selectedHorsesArray);

    // Check if the correct number of races have been selected
    if (selectedRaces.length !== requiredSelections) {
      alert(`Please select exactly ${requiredSelections} races for the ${selectedBet} bet type.`);
      return;
    }

    // Check if a horse has been selected for each race
    const allHorsesSelected = selectedRaces.every(raceId => selectedHorses[raceId]);
    if (!allHorsesSelected) {
      alert("Please select one horse per race.");
      return;
    }

    if (!betAmount) {
      alert("Please enter a bet amount.");
      return;
    }

    try {
      const userId = localStorage.getItem("userId");
      const betData = {
        bet_amount: betAmount,
        bet_total: betAmount * selectedHorsesArray.length,
        bet_type: selectedBet,
        track_name: trackName,
        bet_num_horses: selectedHorsesArray.length,
        user_id: userId,
        horse_order: selectedHorsesArray.map((horse, index) => ({ ...horse, order: index + 1 })),
      };

      selectedHorsesArray.forEach((horse, index) => {
        betData[`horse_id${index + 1}`] = horse.horseId;
      });

      console.log("Submitting bet data:", betData);
      const response = await axios.post(`https://hotponies.com/bets`, betData);
      console.log("Bet finalized successfully:", response.data);
      alert("Bet finalized successfully!");
      navigate('/confirmation', { state: { betData } }); // Redirect to a confirmation page or dashboard
    } catch (error) {
      console.error("Error finalizing bet:", error);
      alert("Error finalizing bet. Please try again.");
    }
  };

  const handleOpenModal = (title, data) => {
    setModalTitle(title);
    setModalData(data);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setModalData(null);
    setModalTitle("");
  };

  const formatDataInColumns = (data, numColumns) => {
    if (!data) return [];
    const keys = Object.keys(data);
    const numRows = Math.ceil(keys.length / numColumns);
    const rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(keys.slice(i * numColumns, i * numColumns + numColumns));
    }
    return rows;
  };

  return (
    <div>
      <button onClick={handleGoBack} style={{ marginTop: "4vh" }}>
        Go Back
      </button>
      <h1>Select A Horse From Each Race</h1>
      <h2>Track: {trackName}</h2>
      
      <div>
        <label htmlFor="raceSelect">Select a race:</label>
        <select
          id="raceSelect"
          value={selectedRaceId || ""}
          onChange={handleRaceSelect}
          style={{ marginLeft: '2.7%', width: 'auto' }}
        >
          <option value="" disabled>Select a race</option>
          {races.map((race) => (
            <option key={race.id} value={race.id}>
              {`Race #: ${race.id} -- Race: ${race.race_name}`}
            </option>
          ))}
        </select>
      </div>

      <label>
        Number of columns to display:
        <select
          value={numColumns}
          onChange={handleNumColumnsChange}
          style={{ marginLeft: '1%', width: "auto" }}
        >
          {Object.keys(races[0] || {}).map((_, index) => (
            <option key={index} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
      </label>

      {loadingRaceDetails ? (
        <p>Loading race details...</p>
      ) : selectedRace ? (
        <div style={{ marginTop: '20px' }}>
          <h3>Race Details:</h3>
          <table>
            <thead>
              <tr>
                {Object.keys(selectedRace)
                  .slice(0, numColumns)
                  .map((key) => (
                    <th key={key}><FormatKey keyText={key} /></th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.keys(selectedRace)
                  .slice(0, numColumns)
                  .map((key) => (
                    <td key={key}>{selectedRace[key]}</td>
                  ))}
              </tr>
            </tbody>
          </table>

          {horseData.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <h3>Horse and Jockey Details:</h3>
              <table>
                <thead>
                  <tr>
                    {Object.keys(horseData[0].horse).slice(0, 4).map((key, index) => (
                      <th key={index}>{FormatKey({ keyText: key })}</th>
                    ))}
                    {Object.keys(horseData[0].jockey).slice(0, 4).map((key, index) => (
                      <th key={index}>{FormatKey({ keyText: key })}</th>
                    ))}
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {horseData.map((data, index) => (
                    <tr key={index}>
                      {Object.keys(data.horse).slice(0, 4).map((key, keyIndex) => (
                        <td 
                          key={keyIndex} 
                          style={{ cursor: 'pointer' }} 
                          onClick={() => handleOpenModal('Horse Details', data.horse)}
                        >
                          {data.horse[key]}
                        </td>
                      ))}
                      {Object.keys(data.jockey).slice(0, 4).map((key, keyIndex) => (
                        <td 
                          key={keyIndex} 
                          style={{ cursor: 'pointer' }} 
                          onClick={() => handleOpenModal('Jockey Details', data.jockey)}
                        >
                          {data.jockey[key]}
                        </td>
                      ))}
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedHorses[selectedRace.id] === data.horse.id}
                          onChange={() => handleCheckboxChange(data.horse.id, selectedRace.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ) : (
        <p>Please select a race to see the details.</p>
      )}

      <div style={{ 
        marginTop: '4vh', 
        position: 'fixed', 
        right: '10vw', 
        top: '20%', 
        backgroundColor: 'lightblue', 
        padding: '20px', 
        borderRadius: '8px', 
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)' 
      }}>

<div style={{ marginTop: '20px' }}>
        <label>
          Bet Amount per Horse:
          <input
            type="number"
            value={betAmount}
            onChange={handleBetAmountChange}
            style={{ marginLeft: '1%', width: '16%' }}
          />
        </label>
        
      </div>
        <h3 style={{ 
          borderBottom: '2px solid darkblue', 
          paddingBottom: '10px', 
          marginBottom: '10px' 
        }}>{selectedBet} --- Selected Horses</h3>
        <div>
          {Object.entries(selectedHorses).map(([raceId, horseId]) => {
            const horse = horseData.find(data => data.horse.id === horseId)?.horse;
            return (
              <div key={raceId} style={{ 
                padding: '10px', 
                backgroundColor: 'white', 
                borderRadius: '4px', 
                marginBottom: '10px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
                <p style={{ 
                  margin: '0', 
                  fontWeight: 'bold' 
                }}>Race {raceId}: <span style={{ 
                  color: 'darkblue' 
                }}>{horse ? horse.horse_name : `Horse ${horseId}`}</span></p>
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: '20px', fontWeight: 'bold', fontSize: '16pt' }}>
          Total Bet Amount: ${betAmount * Object.keys(selectedHorses).length}
        </div>
        <button onClick={handleFinalizeBet} style={{ marginLeft: '30%' }}>
          Finalize Bet
        </button>
      </div>

      <Modal
        visible={modalVisible}
        onClose={handleCloseModal}
        title={modalTitle}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {modalData && formatDataInColumns(modalData, numColumns).map((row, rowIndex) => (
            <div key={rowIndex} style={{ flex: '1 1 30%', padding: '10px', boxSizing: 'border-box' }}>
              {row.map(key => (
                <div key={key}>
                  <strong>{FormatKey({ keyText: key })}:</strong> {modalData[key]}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default AdvancedBetRaces;
