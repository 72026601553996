
// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import FormatKey from "../Modules/FormatKey";
// import { RaceContext } from "../Pages/RaceContext";
// import Clock from "../Modules/MathTime/Clock";

// const TrackCards = () => {
//   const [loadingRaceDetails, setLoadingRaceDetails] = useState(false);
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const id = searchParams.get("id");
//   const dailyRaces = searchParams.get("daily_races");
//   const navigate = useNavigate();
//   const [track, setTrack] = useState(null);
//   const [races, setRaces] = useState(null);
//   const [dataUpdated, setDataUpdated] = useState(false); // Track data update completion
//   const { currentRaceId, setCurrentRaceId, setNumHorses, setNavigateData, race, setRace, setTrackName } = useContext(RaceContext);

//   useEffect(() => {
//     const fetchTrackAndRaces = async () => {
//       try {
//         const trackResponse = await axios.get(`https://hotponies.com/tracks/${id}`);
//         let trackData = trackResponse.data;
//         // console.log('ENTER TRACKCARDS /TRACKS:', trackData);

//         const racesIds = Object.keys(trackData)
//           .filter((key) => key.startsWith("race_id"))
//           .slice(0, dailyRaces)
//           .map((key) => trackData[key]);
// // console.log('TRACK CARD RACE IDs:',racesIds)
//         const currentRaceData = await fetchRacesData(racesIds);
//         setRaces(currentRaceData);
//         setTrack(trackData);
//         setRace(trackData);
//         setTrackName(trackData.track_name); // Update track name in context
//         setDataUpdated(true);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchTrackAndRaces();
//   }, [id, currentRaceId, dailyRaces]);

//   const fetchRacesData = async (racesIds) => {
//     const racePromises = racesIds.map(async (raceId) => {
//       const raceResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
//       // console.log('Races/raceId-raceResponse.data', raceResponse.data,raceResponse.starting_time)

//       return raceResponse.data;
//     });

//     return Promise.all(racePromises);
//   };

//   const handleGoBack = () => {
//     navigate(-1);
//   };

//   const handleRaceClick = async (raceId) => {
//     if (!dataUpdated) {
//       console.warn("Data update is still in progress, please wait.");
//       return;
//     }

//     try {
//       setLoadingRaceDetails(true); 

//       const currentRaceData = await getCurrentRaceData(raceId);
//       const isHorseCountValid = validateHorseCount(currentRaceData);

//       if (!isHorseCountValid) {
//         await updateRaceDataHorses(currentRaceData);
//       }

//       setCurrentRaceId(currentRaceData.id);
//       setNumHorses(currentRaceData.num_horses);
//       navigate(`/race-card?raceId=${raceId}&trackId=${id}`);
//     } catch (error) {
//       console.error("Error occurred during race click:", error);
//     } finally {
//       setLoadingRaceDetails(false);
//     }
//   };

//   const getCurrentRaceData = async (raceId) => {
//     try {
//       const currentRaceResponse = await axios.get(`https://hotponies.com/current_races/${raceId}/${id}`);
//       const currentRaceData = currentRaceResponse.data;
//       // console.log('currentRaceData', currentRaceData)
//       const currentRaceDataId = currentRaceData.id;
//       // console.log('currentRaceDataId', currentRaceData.id)

//       const raceNumHorsesResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
//       const raceNumHorses = raceNumHorsesResponse.data.num_horses;

//       // console.log('raceNumHorses', raceNumHorsesResponse.data.num_horses)

//       if (raceNumHorses !== currentRaceData.num_horses) {
//         await axios.patch(`https://hotponies.com/current_races/${currentRaceDataId}`, {
//           num_horses: raceNumHorses, num_jockeys: raceNumHorses
//         });
//       }

//       // console.log('Retrieved and possibly updated currentRaceData:', currentRaceData);
//       return currentRaceData;
//     } catch (error) {
//       console.error('Error in getCurrentRaceData:', error);
//       throw error;
//     }
//   };

//   const validateHorseCount = (currentRaceData) => {
//     const horseCount = Object.keys(currentRaceData).filter(key => key.startsWith('horse_id') && currentRaceData[key] && currentRaceData[key] > 0).length;
//     // console.log('Current valid horse_id# count:', horseCount);

//     if (horseCount >= 6 && horseCount <= 12) {
//       return true;
//     }
//     return false;
//   };

//   const updateRaceDataHorses = async (currentRaceData) => {
//     const updatedRaceData = adjustHorseCounts(currentRaceData);
//     await updateEndpoints(updatedRaceData);

//     setRaces(prevRaces => prevRaces.map(prevRace => prevRace.id === updatedRaceData.id ? updatedRaceData : prevRace));
//   };

//   const adjustHorseCounts = (currentRaceData) => {
//     const horseCount = Object.keys(currentRaceData).filter(key => key.startsWith('horse_id') && currentRaceData[key] && currentRaceData[key] > 0).length;

//     if (horseCount < 6 || horseCount > 12) {
//       currentRaceData.num_horses = Math.floor(Math.random() * 7) + 6;
//       console.log('Adjusted num_horses:', currentRaceData.num_horses);

//       for (let i = horseCount; i > currentRaceData.num_horses; i--) {
//         const horseIdFieldToRemove = `horse_id${i}`;
//         currentRaceData[horseIdFieldToRemove] = null;
//         console.log(`Removed ${horseIdFieldToRemove}`);
//       }
//     } else {
//       currentRaceData.num_horses = horseCount;
//       // console.log('Horse count between 6 and 12. Set num_horses:', currentRaceData.num_horses);
//     }
//     return currentRaceData;
//   };

//   const updateEndpoints = async (currentRaceData) => {
//     const numHorses = currentRaceData.num_horses;

//     await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
//       num_horses: numHorses,
//       num_jockeys: numHorses,
//     });

//     await axios.patch(`https://hotponies.com/races/${currentRaceData.id}`, {
//       num_horses: numHorses,
//     });

//     for (let i = 1; i <= numHorses; i++) {
//       const horseIdField = `horse_id${i}`;
//       const jockeyIdField = `jockey_id${i}`;

//       if (!currentRaceData[horseIdField] || currentRaceData[horseIdField] < 1) {
//         const horseResponse = await axios.get(`https://hotponies.com/random-records/horses/1`);
//         const newHorseId = horseResponse.data[0].id;

//         await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
//           [horseIdField]: newHorseId,
//         });
//       }

//       if (!currentRaceData[jockeyIdField] || currentRaceData[jockeyIdField] < 1) {
//         const jockeyResponse = await axios.get(`https://hotponies.com/random-records/jockeys/1`);
//         const newJockeyId = jockeyResponse.data[0].id;

//         await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
//           [jockeyIdField]: newJockeyId,
//         });
//       }
//     }

//     for (let i = numHorses + 1; i <= 12; i++) {
//       const horseIdField = `horse_id${i}`;
//       const jockeyIdField = `jockey_id${i}`;

//       await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
//         [horseIdField]: null,
//         [jockeyIdField]: null,
//       });
//     }
//   };

//   return (
//     <div>
//       <button onClick={handleGoBack} style={{ marginTop: "6vh" }}>
//         Go Back
//       </button>
//       <Clock showCountdown={false} />

//       <h2>TRACK CARD:</h2>
//       {track ? (
//         <div>
//           <table>
//             <thead>
//               <tr>
//                 {Object.keys(track)
//                   .slice(0, 13)
//                   .map((key) => (
//                     <th key={key}><FormatKey keyText={key} /></th>
//                   ))}
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 {Object.keys(track)
//                   .slice(0, 13)
//                   .map((key) => (
//                     <td key={key}>{track[key]}</td>
//                   ))}
//               </tr>
//             </tbody>
//           </table>

//           <h2>Todays Current Races:</h2>
//           {races ? (
//             <table >
//               <thead>
//                 <tr >
//                   {Object.keys(races[0])
//                     .slice(0, 13)
//                     .map((key) => (
//                       <th key={key}>
//                         <FormatKey keyText={key} />
//                       </th>
//                     ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {races.map((race) => (
//                   race && (
//                     <tr style={{ cursor: "pointer" }}
//                       key={race.id}
//                       onClick={() => handleRaceClick(race.id)}
//                     >
//                       {Object.keys(race)
//                         .slice(0, 13)
//                         .map((key) => (
//                           <td key={key}>{race[key]}</td>
//                         ))}
//                     </tr>
//                   )
//                 ))}
//               </tbody>
//             </table>
//           ) : (
//             <p>Loading races...</p>
//           )}
//           {loadingRaceDetails && <p>Loading race details...</p>}
//         </div>
//       ) : (
//         <p>Unable to retrieve track information.</p>
//       )}
//     </div>
//   );
// };

// export default TrackCards;






import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import FormatKey from "../Modules/FormatKey";
import { RaceContext } from "../Pages/RaceContext";
import Clock from "../Modules/MathTime/Clock";
import EnterRaceForm from "../Users/EnterRaceForm";

const TrackCards = () => {
  const [loadingRaceDetails, setLoadingRaceDetails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const dailyRaces = searchParams.get("daily_races");
  const navigate = useNavigate();
  const [track, setTrack] = useState(null);
  const [races, setRaces] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false); // Track data update completion
  const { currentRaceId, setCurrentRaceId, setNumHorses, setNavigateData, race, setRace, setTrackName } = useContext(RaceContext);

  useEffect(() => {
    const fetchTrackAndRaces = async () => {
      try {
        const trackResponse = await axios.get(`https://hotponies.com/tracks/${id}`);
        let trackData = trackResponse.data;
        console.log('ENTER TRACKCARDS /TRACKS:', trackData);

        const racesIds = Object.keys(trackData)
          .filter((key) => key.startsWith("race_id"))
          .slice(0, dailyRaces)
          .map((key) => trackData[key]);
        console.log('TRACK CARD RACE IDs:', racesIds);
        
        const currentRaceData = await fetchRacesData(racesIds);
        setRaces(currentRaceData);
        setTrack(trackData);
        setRace(trackData);
        setTrackName(trackData.track_name); // Update track name in context
        setDataUpdated(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchTrackAndRaces();
  }, [id, currentRaceId, dailyRaces]);

  const fetchRacesData = async (racesIds) => {
    const racePromises = racesIds.map(async (raceId) => {
      const raceResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
      console.log('Races/raceId-raceResponse.data', raceResponse.data, raceResponse.starting_time);

      return raceResponse.data;
    });

    return Promise.all(racePromises);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleRaceClick = async (raceId) => {
    if (!dataUpdated) {
      console.warn("Data update is still in progress, please wait.");
      return;
    }

    try {
      setLoadingRaceDetails(true); 

      const currentRaceData = await getCurrentRaceData(raceId);
      const isHorseCountValid = validateHorseCount(currentRaceData);

      if (!isHorseCountValid) {
        await updateRaceDataHorses(currentRaceData);
      }

      setCurrentRaceId(currentRaceData.id);
      setNumHorses(currentRaceData.num_horses);
      navigate(`/race-card?raceId=${raceId}&trackId=${id}`);
    } catch (error) {
      console.error("Error occurred during race click:", error);
    } finally {
      setLoadingRaceDetails(false);
    }
  };

  const getCurrentRaceData = async (raceId) => {
    try {
      const currentRaceResponse = await axios.get(`https://hotponies.com/current_races/${raceId}/${id}`);
      const currentRaceData = currentRaceResponse.data;
      console.log('currentRaceData', currentRaceData);
      const currentRaceDataId = currentRaceData.id;
      console.log('currentRaceDataId', currentRaceData.id);

      const raceNumHorsesResponse = await axios.get(`https://hotponies.com/races/${raceId}`);
      const raceNumHorses = raceNumHorsesResponse.data.num_horses;
      console.log('raceNumHorses', raceNumHorsesResponse.data.num_horses);

      if (raceNumHorses !== currentRaceData.num_horses) {
        await axios.patch(`https://hotponies.com/current_races/${currentRaceDataId}`, {
          num_horses: raceNumHorses, num_jockeys: raceNumHorses
        });
      }

      console.log('Retrieved and possibly updated currentRaceData:', currentRaceData);
      return currentRaceData;
    } catch (error) {
      console.error('Error in getCurrentRaceData:', error);
      throw error;
    }
  };

  const validateHorseCount = (currentRaceData) => {
    const horseCount = Object.keys(currentRaceData).filter(key => key.startsWith('horse_id') && currentRaceData[key] && currentRaceData[key] > 0).length;
    console.log('Current valid horse_id# count:', horseCount);

    if (horseCount >= 6 && horseCount <= 12) {
      return true;
    }
    return false;
  };

  const updateRaceDataHorses = async (currentRaceData) => {
    const updatedRaceData = adjustHorseCounts(currentRaceData);
    await updateEndpoints(updatedRaceData);

    setRaces(prevRaces => prevRaces.map(prevRace => prevRace.id === updatedRaceData.id ? updatedRaceData : prevRace));
  };

  const adjustHorseCounts = (currentRaceData) => {
    const horseCount = Object.keys(currentRaceData).filter(key => key.startsWith('horse_id') && currentRaceData[key] && currentRaceData[key] > 0).length;

    if (horseCount < 6 || horseCount > 12) {
      currentRaceData.num_horses = Math.floor(Math.random() * 7) + 6;
      console.log('Adjusted num_horses:', currentRaceData.num_horses);

      for (let i = horseCount; i > currentRaceData.num_horses; i--) {
        const horseIdFieldToRemove = `horse_id${i}`;
        currentRaceData[horseIdFieldToRemove] = null;
        console.log(`Removed ${horseIdFieldToRemove}`);
      }
    } else {
      currentRaceData.num_horses = horseCount;
      console.log('Horse count between 6 and 12. Set num_horses:', currentRaceData.num_horses);
    }
    return currentRaceData;
  };

  const updateEndpoints = async (currentRaceData) => {
    const numHorses = currentRaceData.num_horses;

    await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
      num_horses: numHorses,
      num_jockeys: numHorses,
    });

    await axios.patch(`https://hotponies.com/races/${currentRaceData.id}`, {
      num_horses: numHorses,
    });

    for (let i = 1; i <= numHorses; i++) {
      const horseIdField = `horse_id${i}`;
      const jockeyIdField = `jockey_id${i}`;

      if (!currentRaceData[horseIdField] || currentRaceData[horseIdField] < 1) {
        const horseResponse = await axios.get(`https://hotponies.com/random-records/horses/1`);
        const newHorseId = horseResponse.data[0].id;

        await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
          [horseIdField]: newHorseId,
        });
      }

      if (!currentRaceData[jockeyIdField] || currentRaceData[jockeyIdField] < 1) {
        const jockeyResponse = await axios.get(`https://hotponies.com/random-records/jockeys/1`);
        const newJockeyId = jockeyResponse.data[0].id;

        await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
          [jockeyIdField]: newJockeyId,
        });
      }
    }

    for (let i = numHorses + 1; i <= 12; i++) {
      const horseIdField = `horse_id${i}`;
      const jockeyIdField = `jockey_id${i}`;

      await axios.patch(`https://hotponies.com/current_races/${currentRaceData.id}`, {
        [horseIdField]: null,
        [jockeyIdField]: null,
      });
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    console.log("Modal opened");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    console.log("Modal closed");
  };

  const handleHorseAdded = (updatedRaceData) => {
    setRaces(prevRaces => prevRaces.map(race => race.id === updatedRaceData.id ? updatedRaceData : race));
    closeModal();
  };

  return (
    <div>
      <button onClick={openModal} style={{ marginTop: "6vh" }}>
        Enter Horse
      </button>
      <Clock showCountdown={false} />

      <h2>TRACK CARD:</h2>
      {track ? (
        <div>
          <table>
            <thead>
              <tr>
                {Object.keys(track)
                  .slice(0, 13)
                  .map((key) => (
                    <th key={key}><FormatKey keyText={key} /></th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.keys(track)
                  .slice(0, 13)
                  .map((key) => (
                    <td key={key}>{track[key]}</td>
                  ))}
              </tr>
            </tbody>
          </table>

          <h2>Todays Current Races:</h2>
          {races ? (
            <table>
              <thead>
                <tr>
                  {Object.keys(races[0])
                    .slice(0, 13)
                    .map((key) => (
                      <th key={key}>
                        <FormatKey keyText={key} />
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {races.map((race) => (
                  race && (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={race.id}
                      onClick={() => handleRaceClick(race.id)}
                    >
                      {Object.keys(race)
                        .slice(0, 13)
                        .map((key) => (
                          <td key={key}>{race[key]}</td>
                        ))}
                    </tr>
                  )
                ))}
              </tbody>
            </table>
          ) : (
            <p>Loading races...</p>
          )}
          {loadingRaceDetails && <p>Loading race details...</p>}
        </div>
      ) : (
        <p>Unable to retrieve track information.</p>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Enter Race"
        ariaHideApp={false} // Add this line if you see console warnings about appElement
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <EnterRaceForm races={races} onHorseAdded={handleHorseAdded} />
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default TrackCards;
