import React from 'react';

function JockeyStats(props) {
    return (
        <div>
            Jockey Stats
        </div>
    );
}

export default JockeyStats;