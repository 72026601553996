import React from 'react';
import Iframe from 'react-iframe';
import RSSFeed from './RSSFeed';

function News() {
//   const websiteUrl = 'https://www.equibase.com/about/trackchanges.html';

  return (
    <div>
      {/* <h1>We are not affilated with TDN</h1>
      <h1>THEY JUST HAVE THE BEST NEW AVAIABLE</h1>

      <div style={{ alignContent: 'center', width: '80vw', height: '94vh' }}>
        <Iframe
          src={websiteUrl}
          id="websiteViewer"
          className="websiteViewer"
          width="100%"
          height="100%"
        />
      </div> */}
      <RSSFeed/>
    </div>
  );
}

export default News;
