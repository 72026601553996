import React from 'react';

function Statistics(props) {
    return (
        <div>
            Statistics
        </div>
    );
}

export default Statistics;