import React from 'react';

function TrainerStats(props) {
    return (
        <div>
            Trainer Stats
        </div>
    );
}

export default TrainerStats;