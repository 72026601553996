
// TrackRaceDates.js

import React from 'react';
import { useParams } from 'react-router-dom';
import RaceDetails from './RaceDetails'; // Assuming RaceDetails is in the same directory
import Clock from '../Modules/MathTime/Clock';

function TrackRaceDates() {
  const { trackId } = useParams();

  return (
    <div>
      {/* <Clock />  */}
      <h1>Track Race Dates </h1>
      <RaceDetails trackId={trackId} />
      {/* You can add more track-specific details or components here */}
    </div>
  );
}

export default TrackRaceDates;
