import React from 'react';

function JockeyStyle(props) {
    return (
        <div>
            Jockey Style
        </div>
    );
}

export default JockeyStyle;