// // src/config.js
// export const config = {
//     backendUrl: process.env.REACT_APP_BACKEND_URL,
//   };
  
 
// src/config.js
export const config = {
    backendUrl: process.env.REACT_APP_BACKEND_URL || 'https://hotponies.com',
    db: {
        user: process.env.DB_USER || 'defaultUser',
        password: process.env.DB_PASSWORD || 'defaultPassword',
        database: process.env.DB_DATABASE || 'defaultDatabase',
        port: parseInt(process.env.DB_PORT, 10) || 5432,
        host: process.env.DB_HOST || 'localhost',
    },
    jwtSecret: process.env.JWT_SECRET || 'defaultJwtSecret',
    sessionSecret: process.env.SESSION_SECRET || 'defaultSessionSecret',
    paths: {
        // images: '/ramdrive/public/images',
        // pics: '/ramdrive/public/pics',
        // documents: '/ramdrive/public/documents'
        images: 'b:/public/images',
        pics: 'b:/public/pics',
        documents: 'b:/public/documents'
    }
};



// // Usage example in a Node.js file or react component
// import { config } from './config';
// console.log(config.db.user);  // Output: 'postgres'
