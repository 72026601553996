import React from 'react';

function TrainerHistory(props) {
    return (
        <div>
            Trainer History
        </div>
    );
}

export default TrainerHistory;