import React, { createContext, useState } from 'react';

export const RaceContext = createContext();

export const RaceProvider = ({ children }) => {
  const [race, setRace] = useState(null);
  const [currentRaceId, setCurrentRaceId] = useState(null);
  const [selectedHorseIds, setSelectedHorseIds] = useState([]);
  const [selectedHorseNames, setSelectedHorseNames] = useState([]);
  const [selectedJockeyId, setSelectedJockeyId] = useState(null);
  const [selectedJockeyNames, setSelectedJockeyNames] = useState([]);
  const [numHorses, setNumHorses] = useState(null);
  const [numJockeys, setNumJockeys] = useState(null);
  const [navigateData, setNavigateData] = useState(null);
  const [updateNavigateData, setUpdateNavigateData] = useState(null);
  const [bets, setBets] = useState([]);
  const [trackName, setTrackName] = useState(''); 
  const [trackId, setTrackId] = useState(null);
  const [selectedHorses, setSelectedHorses] = useState([]); 
  const [selectedBetType, setSelectedBetType] = useState();
  const [postTime, setPostTime] = useState(new Date()); 


// Function to update postTime, ensuring it always receives a Date object
const handleSetPostTime = (timeString) => {
  if (typeof timeString === 'string' && timeString.includes(':')) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    setPostTime(date);
  } else {
    console.error('Invalid timeString provided:', timeString);
  }
};

  return (
    <RaceContext.Provider
      value={{
        race,
        setRace,
        currentRaceId,
        setCurrentRaceId,
        selectedHorseIds,
        setSelectedHorseIds,
        selectedHorseNames,
        setSelectedHorseNames,
        selectedJockeyId,
        setSelectedJockeyId,
        selectedJockeyNames,
        setSelectedJockeyNames,
        numHorses,
        setNumHorses,
        numJockeys,
        setNumJockeys,
        navigateData,
        setNavigateData,
        updateNavigateData,
        setUpdateNavigateData,
        bets,
        setBets,
        trackName, 
        setTrackName,
        selectedHorses, // Add selectedHorses to the context
        setSelectedHorses, // Add setSelectedHorses to the context
        selectedBetType,
        setSelectedBetType,
        trackId,
        setTrackId,
        postTime,
        setPostTime: handleSetPostTime // Provide this function for consistent Date handling
      }}
    >
      {children}
    </RaceContext.Provider>
  );
};
