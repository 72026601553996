import React, { useState, useEffect } from 'react';
import axios from 'axios';

const UserDashboard = () => {
  const [userHorses, setUserHorses] = useState([]);
  const [userEntries, setUserEntries] = useState([]);

  useEffect(() => {
    axios.get('/usersApi/user_horses')
      .then(response => setUserHorses(response.data))
      .catch(error => console.error('Error fetching horses:', error));

    axios.get('/usersApi/user_entries')
      .then(response => setUserEntries(response.data))
      .catch(error => console.error('Error fetching entries:', error));
  }, []);

  return (
    <div>
      <h2>Your Horses</h2>
      <ul>
        {userHorses.map(horse => (
          <li key={horse.id}>{horse.horse_name}</li>
        ))}
      </ul>
      <h2>Your Entries</h2>
      <ul>
        {userEntries.map(entry => (
          <li key={entry.id}>{entry.race_name} - {entry.horse_name}</li>
        ))}
      </ul>
    </div>
  );
};

export default UserDashboard;
