



//show database with dropdown list

// import { useState, useEffect } from 'react';
// import axios from 'axios';

// function ShowDB() {
//   const [tables, setTables] = useState([]);
//   const [selectedTables, setSelectedTables] = useState([]);
//   const [fields, setFields] = useState([]);
//   const [numColumns, setNumColumns] = useState(1);

//   useEffect(() => {
//     axios.get(' https://hotponies.com/tables')
//       .then(response => setTables(response.data))
//       .catch(error => console.error(error));
//   }, []);

//   useEffect(() => {
//     if (selectedTables.length > 0) {
//       const fetchFields = async () => {
//         const fieldsData = await Promise.all(selectedTables.map(table => (
//           axios.get(` https://hotponies.com/${table}/fields`)
//             .then(response => response.data)
//             .catch(error => console.error(error))
//         )));
//         setFields(fieldsData);
//       }
//       fetchFields();
//     } else {
//       setFields([]);
//     }
//   }, [selectedTables]);

//   function handleTableClick(table) {
//     if (selectedTables.includes(table)) {
//       setSelectedTables(selectedTables.filter(t => t !== table));
//     } else {
//       setSelectedTables([...selectedTables, table]);
//     }
//   }

//   function handleNumColumnsChange(event) {
//     setNumColumns(parseInt(event.target.value));
//   }

//   return (
//     <div>
//       <div>
//         <label htmlFor="numColumns">Number of columns:</label>
//         <select id="numColumns" name="numColumns" value={numColumns} onChange={handleNumColumnsChange}>
//           {[1, 2, 3, 4, 5].map(num => (
//             <option key={num} value={num}>{num}</option>
//           ))}
//         </select>
//       </div>
//       <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
//         {tables.sort().map(table => (
//           <div
//             key={table}
//             style={{
//               border: '1px solid black',
//               padding: '5px',
//               margin: '5px',
//               width: `calc(100% / ${numColumns} - 10px)`,
//               boxSizing: 'border-box'
//             }}
//           >
//             <h3 onClick={() => handleTableClick(table)}>{table}</h3>
//             {selectedTables.includes(table) && fields[selectedTables.indexOf(table)]?.map(field => (
//               <ul style={{ listStyleType: 'none', padding: 0 }}>
//                 <li key={field}>{field}</li>
//               </ul>
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ShowDB;


// show database contents without dropdown lists

import { useState, useEffect } from 'react';
import axios from 'axios';

function ShowDB() {
  const [tables, setTables] = useState([]);
  const [fields, setFields] = useState({});
  const [numColumns, setNumColumns] = useState(1);

  useEffect(() => {
    axios.get('https://hotponies.com/tables')
      .then(response => setTables(response.data))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    const fetchFields = async () => {
      const fieldsData = {};
      await Promise.all(tables.map(async (table) => {
        const response = await axios.get(`https://hotponies.com/${table}/fields`);
        fieldsData[table] = response.data;
      }));
      setFields(fieldsData);
    };
    if (tables.length > 0) {
      fetchFields();
    }
  }, [tables]);

  function handleNumColumnsChange(event) {
    setNumColumns(parseInt(event.target.value));
  }

  return (
    <div>
      <div>
        <label htmlFor="numColumns">Number of columns:</label>
        <select id="numColumns" name="numColumns" value={numColumns} onChange={handleNumColumnsChange}>
          {[1, 2, 3, 4, 5].map(num => (
            <option key={num} value={num}>{num}</option>
          ))}
        </select>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {tables.sort().map(table => (
          <div
            key={table}
            style={{
              border: '1px solid black',
              padding: '5px',
              margin: '5px',
              width: `calc(100% / ${numColumns} - 10px)`,
              boxSizing: 'border-box'
            }}
          >
            <h3>{table}</h3>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {fields[table]?.map(field => (
                <li key={field}>{field}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShowDB;






// SHOWS ALL THE TABLES AND WHEN CLICKED DROPS DOWN THAT COLUMN NAMES

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// function ShowDB() {
//   const [schemas, setSchemas] = useState([]); // State for storing schemas
//   const [tables, setTables] = useState([]);
//   const [selectedTables, setSelectedTables] = useState([]);
//   const [fields, setFields] = useState([]);
//   const [numColumns, setNumColumns] = useState(1);
//   const [selectedSchema, setSelectedSchema] = useState(''); // State for selected schema

//   // Fetch schemas on component mount
//   useEffect(() => {
//     axios.get(' https://hotponies.com/schemas')
//       .then(response => setSchemas(response.data))
//       .catch(error => console.error(error));
//   }, []);

//   // Fetch tables on component mount
//   useEffect(() => {
//     axios.get(' https://hotponies.com/tables')
//       .then(response => setTables(response.data))
//       .catch(error => console.error(error));
//   }, []);

//   // Fetch fields when tables are selected
//   useEffect(() => {
//     if (selectedTables.length > 0) {
//       const fetchFields = async () => {
//         const fieldsData = await Promise.all(selectedTables.map(table => (
//           axios.get(` https://hotponies.com/${table}/fields`)
//             .then(response => response.data)
//             .catch(error => console.error(error))
//         )));
//         setFields(fieldsData);
//       }
//       fetchFields();
//     } else {
//       setFields([]);
//     }
//   }, [selectedTables]);

//   // Handle schema selection change
//   const handleSchemaChange = (event) => {
//     setSelectedSchema(event.target.value);
//   };

//   // Handle table click to toggle selection
//   const handleTableClick = (table) => {
//     if (selectedTables.includes(table)) {
//       setSelectedTables(selectedTables.filter(t => t !== table));
//     } else {
//       setSelectedTables([...selectedTables, table]);
//     }
//   };

//   // Handle number of columns change
//   const handleNumColumnsChange = (event) => {
//     setNumColumns(parseInt(event.target.value));
//   };

//   return (
//     <div>
//       <div>
//         {/* Dropdown for selecting schemas */}
//         <label htmlFor="schemas">Select Schema:</label>
//         <select id="schemas" name="schemas" value={selectedSchema} onChange={handleSchemaChange}>
//           <option value="">Select Schema</option>
//           {schemas.map(schema => (
//             <option key={schema} value={schema}>{schema}</option>
//           ))}
//         </select>
//       </div>
//       <div>
//         {/* Dropdown for selecting the number of columns */}
//         <label htmlFor="numColumns">Number of columns:</label>
//         <select id="numColumns" name="numColumns" value={numColumns} onChange={handleNumColumnsChange}>
//           {[1, 2, 3, 4, 5].map(num => (
//             <option key={num} value={num}>{num}</option>
//           ))}
//         </select>
//       </div>
//       <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
//         {/* Display tables and fields */}
//         {tables.sort().map(table => (
//           <div
//             key={table}
//             style={{
//               border: '1px solid black',
//               padding: '5px',
//               margin: '5px',
//               width: `calc(100% / ${numColumns} - 10px)`,
//               boxSizing: 'border-box'
//             }}
//           >
//             <h3 onClick={() => handleTableClick(table)}>{table}</h3>
//             {selectedTables.includes(table) && fields[selectedTables.indexOf(table)]?.map(field => (
//               <ul style={{ listStyleType: 'none', padding: 0 }}>
//                 <li key={field}>{field}</li>
//               </ul>
//             ))}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ShowDB;
