//These routes are consumed in the Navbar.js component
import React, { useState } from "react";
import FreeRandomData from './Components/Modules/FreeRandomData';
import { FreeProvider } from "./Components/Pages/FreeContext";
import { HashRouter as Router, Routes, Route, Navigate,} from "react-router-dom"; // adds # to resolve server routing issues
import { RaceProvider } from "./Components/Pages/RaceContext";
import ProtectedRoutes from "./Components/Modules/Auth/ProtectedRoutes";
import Auth from "./Components/Modules/Auth/Auth.js";
// import CityAuth from "./Components/Modules/Auth/CityAuth.js";
import AdvancedBetRaces from "./Components/Pages/AdvancedBetRaces.js";
import AdvancedBetTracks from "./Components/Pages/AdvancedBetTracks.js";
import AppTest from "./Components/Pages/AppTest";
import Auction from "./Components/Modules/Auction/Auction";
import AuctionHorses from "./Components/Modules/Auction/AuctionHorses";
import AuctionResults from "./Components/Modules/Auction/AuctionResults";
import BetOdds from "./Components/Pages/BetOdds";
import BetPayouts from "./Components/Pages/BetPayouts";
import Bets from "./Components/Pages/Bets";
import BetTypes from "./Components/Pages/BetTypes";
import Bidding from "./Components/Modules/Auction/Bidding";
import Breeding from "./Components/Pages/Breeding";
import CreateHorsepage from "./Components/Pages/CreateHorsePage";
import CreateRaces from "./Components/Modules/CreateRaces";
import AddColumns from "./Components/Utils/Maintenance/AddColumns";
import Confirmation from "./Components/Pages/Confirmation.js";
import CurrentHighestBid from "./Components/Modules/Auction/CurrentHighestBid";
import CurrentRaceScreens from "./Components/Pages/CurrentRaceScreens";
import Dashboard from "./Components/Pages/Dashboard";
// import DataGenerator from "./Components/Modules/Database/DataGenerator.js";
import PredictRace from "./Components/Pages/PredictRace.js";
import Earnings from "./Components/Pages/Earnings";
import Facilities from "./Components/Modules/Auction/Facilities";
import FAQs from "./Components/Pages/FAQs";
import FreeRace from "./Components/Pages/FreeRace";
import FreeProgramCard from "./Components/Cards/FreeProgramCard";
import FreeToteBoards from "./Components/Pages/FreeToteBoards";
import FreeLeaderBoards from "./Components/Pages/FreeLeaderboards";
import Gear from "./Components/Pages/Gear";
import Hamburger from "./Components/Pages/Hamburger";
import Home from "./Components/Pages/Home";
import Horse from "./Components/Pages/Horse";
import HorseDetails from "./Components/Modules/Auction/HorseDetails";
import MorningLineOdds from "./Components/Pages/MorningLineOdds.js";
import HorseTraining from "./Components/Pages/HorseTraining";
import Instructions from "./Components/Pages/Instructions";
import InsertColumns from "./Components/Utils/Maintenance/InsertColumns";
import Jockey from "./Components/Pages/Jockey";
import JockeyHistory from "./Components/Pages/JockeyHistory";
import JockeyStats from "./Components/Pages/JockeyStats";
import JockeyStyle from "./Components/Pages/JockeyStyle";
import Leaderboard from "./Components/Pages/Leaderboard";
import LearnAboutRacing from "./Components/Pages/LearnAboutRacing";
import MultiRaceBet from "./Components/Pages/MultiRaceBet.js";  
// added this working on bets
import News from "./Components/Pages/News";
import Owner from "./Components/Pages/Owner";
import PageHorse from "./Components/Pages/PageHorse";
import PageJockey from "./Components/Pages/PageJockey";
import PageOwner from "./Components/Pages/PageOwner";
import PageRace from "./Components/Pages/PageRace";
import PageTrack from "./Components/Pages/PageTrack";
import PageTrainer from "./Components/Pages/PageTrainer";
import Profile from "./Components/Modules/Profile/Profile";
import ProgramCard from "./Components/Cards/ProgramCard";
import RaceResults from "./Components/Pages/RaceResults";
import Race from "./Components/Pages/Race";
import Races from "./Components/Pages/Races";
import RaceCards from "./Components/Cards/RaceCards";
import RaceDetails from "./Components/Pages/RaceDetails.js";
import RaceTrack from "./Components/Pages/RaceTrack";
import RaceOddsCalculator from "./Components/Pages/RaceOddsCalculator.js";
import SalesContract from "./Components/Modules/Auction/SalesContract";
import ShowDB from "./Components/Modules/Database/ShowDB";
import Register from "./Components/Modules/Auth/Register";
import SensiPassRegister from "./Components/Modules/Auth/SensiPassRegister";
import Staff from "./Components/Modules/Auction/Staff";
import Statistics from "./Components/Pages/Statistics";
import TrackCards from "./Components/Cards/TrackCards";
import TrackConditions from "./Components/Pages/TrackConditions";
import TrackRaceDates from "./Components/Pages/TrackRaceDates.js";
import TrackType from "./Components/Pages/TrackType";
import Trainer from "./Components/Pages/Trainer";
import TrainerHistory from "./Components/Pages/TrainerHistory";
import TrainerStats from "./Components/Pages/TrainerStats";
import TrainerStyle from "./Components/Pages/TrainerStyle";
import Transportation from "./Components/Modules/Auction/Transportation";
import Tutorial from "./Components/Pages/Tutorial";
import UserBets from "./Components/Users/UserBets.js";
import UserDashboard from "./Components/Users/UserDashboard.js";
import CreateHorseForm from "./Components/Users/CreateHorseForm.js";
import EnterRaceForm from "./Components/Users/EnterRaceForm.js";
import EndPoint from "./Components/Utils/Maintenance/EndpointTest.js";
import AdminProfile from "./Components/Modules/Profile/AdminProfile";
import RunScanInsert from "./Components/Utils/Maintenance/RunScanInsert";
import Navbar from "./Components/Pages/Navbar";
import Login from "./Components/Modules/Auth/Login";
import Logout from "./Components/Modules/Auth/Logout";
import DataConsumer from "./Components/Pages/DataConsumer.js";
import DataProvider from "./Components/Pages/DataProvider.js";

const App = ({ startImage, gateImage, trackImage }) => {
  const [race, setRace] = useState(null);
 
  return (
    <Router>
      <RaceProvider>
        <FreeProvider>
        <>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/data-consumer" element={<DataConsumer />} />
            <Route path="/data-provider" element={<DataProvider />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/race/:id" element={<Race />} />
            <Route path="/learn-about-racing" element={<LearnAboutRacing />} />
            <Route path="/sensi-pass-register" element={<SensiPassRegister/>}/>
            <Route path="/auth" element={<Auth/>} />
            {/* <Route path="/city-auth" element={<CityAuth/>} /> */}
            <Route path="/login" element={<Login />}/>
            <Route path="/login/:id" element={<Login />} />
            <Route path="/logout"   element={<Logout />} />
            <Route path="/logout/:id" element={<Logout />} />
            <Route path="/news" element={<News />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/instructions" element={<Instructions />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/register" element={<Register />} />
            <Route path="/free-race" element={<FreeRace />} />    
            {/* <Route path="/free-race-list" element={<FreeRaceList />} />     */}
            <Route path="/free-random-data" element={<FreeRandomData />} />   
            <Route path="/free-tote-board" element={<FreeToteBoards />} /> 
            <Route path="/free-leader-board" element={<FreeLeaderBoards />} /> 
            <Route path="/free-program-card" element={<FreeProgramCard />} />   
            <Route path="/app-test/:id" element={<AppTest />} />
            <Route path="/app-test" element={<AppTest />} />

      <Route element={<ProtectedRoutes />}>
              <Route path="/race-card" element={<RaceCards />} />
              <Route path="/horse" element={<Horse />} />
              <Route path="/admin-profile" element={<AdminProfile />} />
              <Route path="/advanced-bet-races" element={<AdvancedBetRaces />} />
              <Route path="/advanced-bet-tracks" element={<AdvancedBetTracks />} />
              <Route path="/auction" element={<Auction />} />
              <Route path="/bet-odds" element={<BetOdds />} />
              <Route path="/bet-payouts" element={<BetPayouts />} />
              <Route path="/bet-types" element={<BetTypes />} />
              <Route path="/bets" element={<Bets />} />
              <Route path="/breeding" element={<Breeding />} />
              <Route path="/confirmation" element={<Confirmation />} />
              <Route path="/create-horse-page" element={<CreateHorsepage />} />
              <Route path="/create-races" element={<CreateRaces />} />
              <Route path="/current-race-screens" element={<CurrentRaceScreens />} />
              <Route path="/earnings" element={<Earnings />} />
              <Route path="/gear" element={<Gear />} />
              <Route path="/hamburger" element={<Hamburger />} />
              <Route path="/horse-training" element={<HorseTraining />} />
              <Route path="/horse/:id" element={<Horse />} />
              <Route path="/morning-line-odds" element={<MorningLineOdds/>}/>  
              <Route path="/predict-race" element={<PredictRace/>}/>            
              <Route path="/insert-columns" element={<InsertColumns />} />
              <Route path="/jockey-History" element={<JockeyHistory />} />
              <Route path="/jockey-Stats" element={<JockeyStats />} />
              <Route path="/jockey-Style" element={<JockeyStyle />} />
              <Route path="/jockey" element={<Jockey />} />
              <Route path="/jockey/:id" element={<Jockey />} />
              <Route path="/leaderboard/:id" element={<Leaderboard />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              {/* <Route path="/multui-race-bet/:id" element={<MultiRaceBet />} /> */}
              <Route path="/multi-race-bet" element={<MultiRaceBet />} />
              <Route path="/owner/:id" element={<Owner />} />
              <Route path="/owner" element={<Owner />} />
              <Route path="/page-horse/:id" element={<PageHorse />} />
              <Route path="/page-jockey/:id" element={<PageJockey />} />
              <Route path="/page-owner/:id" element={<PageOwner />} />
              <Route path="/page-race/:id" element={<PageRace />} />  
              <Route path="/page-track/:id" element={<PageTrack />} />
              <Route path="/page-trainer/:id" element={<PageTrainer />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/race-results" element={<RaceResults />} />
              <Route path="/race-track" element={<RaceTrack />} />
              <Route path="/race-track/:id" element={<RaceTrack />} />
              <Route path="/program-card/:id" element={<ProgramCard />} />
              <Route path="/program-card" element={<ProgramCard />} />
              <Route path="/races/:id" element={<Races />} />
              <Route path="/races" element={<Races />} />
              <Route path="/race" element={<Race/>} />
              <Route path="/race-details" element={<RaceDetails />} />
              <Route path="/race-odds-calculator" element={<RaceOddsCalculator />} />
              <Route path="/showDB" element={<ShowDB />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route path="/track-card" element={<TrackCards />} />
              <Route path="/track-conditions" element={<TrackConditions />} />
              <Route path="track-race-dates" element={<TrackRaceDates/>} />
              <Route path="/track-race-dates/:trackId" element={<TrackRaceDates />} />
              <Route path="/track-type" element={<TrackType />} />
              <Route path="/trainer-history" element={<TrainerHistory />} />
              <Route path="/trainer-stats" element={<TrainerStats />} />
              <Route path="/trainer-style" element={<TrainerStyle />} />
              <Route path="/trainer/:id" element={<Trainer />} />
              <Route path="/trainer" element={<Trainer />} />
              {/* AUCTION MODULE SECTION */}
              <Route path="/auction-horses" element={<AuctionHorses />} />
              <Route path="/horse-details" element={<HorseDetails />} />
              <Route path="/bidding" element={<Bidding />} />
              <Route path="/current-highest-bid" element={<CurrentHighestBid />} />
              <Route path="/auction-results" element={<AuctionResults />} />
              <Route path="/facilities" element={<Facilities />} />
              <Route path="/staff" element={<Staff />} />
              <Route path="/sales-contract" element={<SalesContract />} />
              <Route path="/transportation" element={<Transportation />} />
              {/* USER SECTION */}
              <Route path="/user-bets" element={<UserBets />} />
              <Route path="/enter-race" element={<EnterRaceForm />} />
              <Route path="/create-horse" element={<CreateHorseForm />} />
              <Route path="/user-dashboard" element={<UserDashboard />} />
              {/* UTILITIES SECTION */}
              <Route path="/add-columns" element={<AddColumns />} />
              {/* <Route path="/data-generator" element={<DataGenerator />} />         */}
              <Route path="/endpoint-test" element={<EndPoint />} />
              <Route path="/run-scan-insert" element={<RunScanInsert />} />
              {/* <Route path="/move-current-to-past" element={<MoveCurrenetToPast />} /> */}
            </Route>
      </Routes>
        </>
        </FreeProvider>
      </RaceProvider>
    </Router>
  );
};

export default App;
