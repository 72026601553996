import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import FormatKey from "../Modules/FormatKey";

const UserBets = () => {
  const [bets, setBets] = useState([]);
  const [sortColumn, setSortColumn] = useState('bet_date');
  const [sortOrder, setSortOrder] = useState('desc');
  const [numColumns, setNumColumns] = useState(8);
  const [numRows, setNumRows] = useState(20);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [loggedInUserId, setLoggedInUserId] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    setLoggedInUserId(userId);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('id');

    if (userId && loggedInUserId && userId === loggedInUserId) {
      axios.get(`/usersApi/user_bets`, { params: { user_id: userId } })
        .then(response => {
          const filteredData = response.data.filter(record => record.user_id.toString() === userId);
          const sortedData = sortData(filteredData, sortColumn, sortOrder);
          setBets(sortedData.slice(0, numRows));
          const columns = getUniqueColumns(filteredData);
          setTableColumns(columns);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setBets([]);
          setTableColumns([]);
        });
    }
  }, [loggedInUserId, sortColumn, sortOrder, numRows, location.search]);

  const handleSort = (column) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
  };

  const handleNumColumnsChange = event => {
    setNumColumns(parseInt(event.target.value, 10));
  };

  const handleNumRowsChange = event => {
    setNumRows(parseInt(event.target.value, 10));
  };

  const handleToggleColumns = () => {
    setShowAllColumns(!showAllColumns);
  };

  const sortData = (data, column, order) => {
    return data.sort((a, b) => {
      const valueA = a[column];
      const valueB = b[column];
      return (valueA < valueB) ? (order === 'asc' ? -1 : 1) : (valueA > valueB ? 1 : -1);
    });
  };

  const getUniqueColumns = (data) => {
    const columns = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(column => {
        if (column !== 'id') {
          columns.add(column);
        }
      });
    });
    return Array.from(columns);
  };

  const renderTableHeader = () => {
    const columnsToDisplay = showAllColumns ? tableColumns : tableColumns.slice(0, numColumns);
    return (
      <thead>
        <tr>
          {columnsToDisplay.map((header, index) => (
            <th key={index} onClick={() => handleSort(header)}>
              {FormatKey({ keyText: header })} {sortColumn === header && <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const renderTableData = () => {
    const columnsToDisplay = showAllColumns ? tableColumns : tableColumns.slice(0, numColumns);
    return (
      <tbody>
        {bets.map((bet, index) => (
          <tr key={index}>
            {columnsToDisplay.map((column, idx) => (
              <td key={idx}>{bet[column]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ maxWidth: '98vw', marginLeft: '2vw' }}>
      <button onClick={handleGoBack} style={{ marginTop: "4vh" }}>Go Back</button>
      <h2>Betting History</h2>
      <div>
        <label>Number of Columns:</label>
        <input type="number" value={numColumns} onChange={handleNumColumnsChange} min="1" />
      </div>
      <div>
        <label>Number of Rows:</label>
        <input type="number" value={numRows} onChange={handleNumRowsChange} min="1" />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '74%' }}>
        <label>Show All Columns:</label>
        <input type="checkbox" checked={showAllColumns} onChange={handleToggleColumns} />
      </div>
      {bets.length > 0 ? (
        <table>
          {renderTableHeader()}
          {renderTableData()}
        </table>
      ) : (
        <p>No bets found for the user.</p>
      )}
    </div>
  );
};

export default UserBets;
