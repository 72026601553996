// authUtils.js

// Function to set the authentication token
export function setAuthToken(token) {
    // You can store the token in local storage or wherever you prefer
    localStorage.setItem('authToken', token);
  }
  
  // Function to get the authentication token
  export function getAuthToken() {
    // Retrieve the token from local storage or wherever you stored it
    return localStorage.getItem('authToken');
  }
  