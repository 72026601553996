import React, { useContext } from "react";
import { RaceContext } from "../Pages/RaceContext";
// import "../Styles/Leaderboard.css";

const Leaderboard = () => {
  // Access data from the RaceContext
  const { race, currentRaceId, selectedHorseIds, selectedHorseNames } = useContext(RaceContext);

  // Log the values to the console for debugging
  // console.log("Leaderboard Component - Race:", race);
  // console.log("Leaderboard Component - Selected Horse IDs:", selectedHorseIds);
  // console.log("Leaderboard Component - Selected Horse Names:", selectedHorseNames);
  // console.log("Selected Horse IDs Length:", selectedHorseIds.length);
  // console.log("Selected Horse Names Length:", selectedHorseNames.length);

  // Render a loading message if any required data is missing
  if (!race || !selectedHorseIds || !selectedHorseNames) {
    console.log("Leaderboard Component - Loading because data is missing...");
    return <p>Loading...</p>;
  }

  return (
    <div className="leaderboard-overlay">
      {/* Render the leaderboard content if data is available */}
      <h2 id="leaderboard-title">Leaderboard</h2>
      <h4 id="leaderboard-race-id" style={{marginLeft:'3vw'}}>Race #{currentRaceId}</h4>
      <div className="leaderboard-container">
        
        <div className="horse-list">
          <h5 style={{marginLeft:'3vw'}}># - Horse:</h5>
         <h5> <ul >
            {selectedHorseNames.slice(0, 12).map((horseName, index) => (
              <li key={index}>
                <div className="index-number">{index + 1} -  {horseName}</div>
              </li>
            ))}
          </ul></h5>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;

