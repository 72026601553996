import React from 'react';

function Gear(props) {
    return (
        <div>
            Horse Racing Gear
        </div>
    );
}

export default Gear;