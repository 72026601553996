import React from "react";
import QRCode from 'qrcode.react'; 


const SensiPassInstructions = () => {
 // URL for downloading the SensiPass app from the Google Play Store
 const playStoreUrl =
 "https://play.google.com/store/apps/details?id=ie.cit.nimbus.sensipass.sensipassandroid198eval";

  return (
    <div>
      {/* <h1 style={{ marginTop: "-1.5%" }}>SensiPass</h1> */}

    
      <h2 style={{ marginTop: "1%" }}>Step 1: Watch a quick Trailer</h2>
      <p>Take a quick peek. See how simiple and secure logging will be.</p>
      <a href="https://youtu.be/KZWROMV85Es" target="_blank">
        <button>Watch Trailer</button>
      </a>
      <h2>Step 2:</h2>
      <p>
        Visit the Google Play Store to download the SensiPass app on your
        <p style={{ marginTop: "-1.5%" }}> </p>
        Android device. Scan the QR Code to go to the Google Play Store:
      </p>
      {/* <a
        href="https://play.google.com/store/apps/details?id=ie.cit.nimbus.sensipass.sensipassandroid198eval"
        target="_blank"
      >
        <button>Download from Google Play</button></a>       */}


 {/* Display the QR code for the Google Play Store link */}
 <div>
        <QRCode value={playStoreUrl} />
      </div>

      <h2>Step 3: Install the App</h2>
      <p>
        Once you've downloaded the SensiPass app,
        <p style={{ marginTop: "-.5%" }}>
          follow the on screen steps to install it on your Android device:
        </p>
      </p>
      <p style={{ marginTop: "-1%" }}>
        Now follow the on-screen instructions to complete the installation. Return here and login.
      </p>
      <p style={{ marginTop: "-1.5%" }}> </p>
      {/* <a href="https://sensipass.com" target="_blank">
        <button>LEARN MORE</button>
      </a> */}
<h2><a href="mailto:support@hotponies.com">Need Help? email: support@hotponies.com</a></h2>
      <p>
        If you encounter any issues or need assistance with the installation,
        please contact our support team at support@hotponies.com.
      </p>
    </div>
  );
};

export default SensiPassInstructions;



