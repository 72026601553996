import React from 'react';

function Breeding(props) {
    return (
        <div>
            Breeding
        </div>
    );
}

export default Breeding;