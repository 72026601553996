// The AuthProvider sets up a context for managing user authentication data and provides a way for components to log in and log out users 
// by updating the user state and making these functions available through the useAuth hook. 
// It's a foundational piece of an authentication system for a React application.



import React, { createContext, useContext, useState } from 'react';

// Step 1: Create an authentication context to manage user authentication state
export const AuthContext = createContext();

// Step 2: Create a custom hook to easily access the authentication context
export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  // Step 3: Define the initial user state, including name and authentication status
  const [user, setUser] = useState({ name: '', isAuthenticated: false });

  // Step 4: Define a login function that interacts with an API (not shown here)
  const login = (userName, password) => {
    // MAKE CALL TO API TO CHECK USER NAME (this part would typically involve an API call)

    // Step 5: Return a promise to handle the result of the login attempt
    return new Promise((resolve, reject) => {
      // Step 6: Check if the provided password is correct
      if (password === 'password') {
        // Step 7: If correct, update the user state to reflect authentication
        setUser({ name: userName, isAuthenticated: true });
        resolve('success'); // Resolve the promise to indicate successful login
      } else {
        // Step 8: If incorrect, reject the promise with an error message
        reject('Incorrect password'); // Reject the promise with an error message
      }
    });
  };

  // Step 9: Define a logout function to reset the user state
  const logout = () => {
    setUser({ name: '', isAuthenticated: false });
  };

  // Step 10: Create an object with the authentication context values
  const authContextValue = {
    user,     // Current user information
    login,    // Function to initiate login
    logout,   // Function to initiate logout
  };

  // Step 11: Provide the authentication context to the component tree
  return (
    <AuthContext.Provider value={authContextValue}>
      {children} {/* Step 12: Render child components */}
    </AuthContext.Provider>
  );
};

export default AuthProvider;



// AuthContext is created using createContext(). It's like a storage container where authentication data and functions will be 
// stored and shared with other components.

// Inside the AuthProvider component, there is a user state variable that represents the current user's information. Initially, 
// it's set to an empty user object with an isAuthenticated property set to false, indicating that no user is logged in.

// The login function is defined to handle user login attempts. It takes a username and a password as arguments and simulates an 
// API call to check if the provided password matches a hardcoded value ('password' in this case). If the password is correct, it 
// updates the user state to include the username and sets isAuthenticated to true. If the password is incorrect, it rejects the login attempt.

// The logout function is a simple function that resets the user state to its initial empty state, effectively logging the user out.

// authContextValue is an object containing the user, login, and logout functions. This object is used to provide these values and 
// functions to components within its child components.

// Finally, the AuthProvider component wraps its children (other components) with the AuthContext.Provider. This makes the user, login, 
// and logout values and functions accessible to any component that uses the useAuth hook within its child components.






















// import React, { createContext, useContext, useState } from 'react';

// export const AuthContext = createContext();

// export const useAuth = () => useContext(AuthContext);

// export const AuthWrapper = ({ children }) => {
//   const [user, setUser] = useState({ name: '', isAuthenticated: false });

//   const login = (userName, password) => {
//     // MAKE CALL TO API TO CHECK USER NAME

//     return new Promise((resolve, reject) => {
//       if (password === 'password') {
//         setUser({ name: userName, isAuthenticated: true });
//         resolve('success');
//       } else {
//         reject('Incorrect password');
//       }
//     });
//   };

//   const logout = () => {
//     setUser({ ...user, isAuthenticated: false });
//   };

//   return (
//     <AuthContext.Provider value={{ user, login, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
