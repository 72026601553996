import React from 'react';

function TrainerStyle(props) {
    return (
        <div>
            Trainer Style
        </div>
    );
}

export default TrainerStyle;