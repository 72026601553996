
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Styles/Confirmation.css'; // Import a CSS file for styling

const Confirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { betData } = location.state || {};
  const [trackDetails, setTrackDetails] = useState(null);
  const [raceDetails, setRaceDetails] = useState([]);
  const [horseDetails, setHorseDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!betData) {
      navigate('/');
      return;
    }

    const fetchDetails = async () => {
      try {
        const trackResponse = await axios.get(`https://hotponies.com/tracks?track_name=${betData.track_name}`);
        setTrackDetails(trackResponse.data[0]);

        const racePromises = betData.horse_order.map(async (horse) => {
          const raceResponse = await axios.get(`https://hotponies.com/races/${horse.raceId}`);
          return raceResponse.data;
        });

        const horsePromises = betData.horse_order.map(async (horse) => {
          const horseResponse = await axios.get(`https://hotponies.com/horses/${horse.horseId}`);
          return horseResponse.data;
        });

        const fetchedRaces = await Promise.all(racePromises);
        const fetchedHorses = await Promise.all(horsePromises);

        setRaceDetails(fetchedRaces);
        setHorseDetails(fetchedHorses);
      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [betData, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!betData) {
    return <div>No bet data available.</div>;
  }

  const totalBetAmount = betData.bet_amount * betData.horse_order.length;

  return (
    <div className="confirmation-container">
      <h1 className="confirmation-title">Bet Confirmation</h1>
      <h2 className="track-name">Track: {betData.track_name}</h2>
      {trackDetails && (
        <div className="track-details">
          <h3>Track Details:</h3>
          <p><strong>Track Name:</strong> {trackDetails.track_name}</p>
          <p><strong>Location:</strong> {trackDetails.location}</p>
          <p><strong>Race Dates:</strong> {trackDetails.race_date}</p>
        </div>
      )}
      <div className="bet-details">
        <h3>Bet Type: {betData.bet_type}</h3>
        <h3>Total Bet Amount: ${totalBetAmount.toFixed(2)}</h3>
      </div>
      <h3>Races and Horses:</h3>
      {betData.horse_order.map((horse, index) => (
        <div key={index} className="race-horse-details">
          <h4>Race {index + 1}:</h4>
          {raceDetails[index] && (
            <div>
              <p><strong>Race Name:</strong> {raceDetails[index].race_name}</p>
              <p><strong>Race Date:</strong> {raceDetails[index].race_date}</p>
              <p><strong>Post Time:</strong> {raceDetails[index].starting_time}</p>
            </div>
          )}
          {horseDetails[index] && (
            <div>
              <p><strong>Horse Name:</strong> {horseDetails[index].horse_name}</p>
              <p><strong>Horse Number:</strong> {horseDetails[index].horse_number}</p>
            </div>
          )}
        </div>
      ))}
      <button onClick={() => navigate('/')} className="back-button">Back to Home</button>
    </div>
  );
};

export default Confirmation;
