import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import PreloadedImages from "../Modules/PreLoadedImages";
import { RaceContext } from "./RaceContext";
import RaceResultsHandler from "./RaceResultsHandler";
import { config } from '../../config';

function Race({ startImage, gateImage, trackImage }) {
  const [finishOrder, setFinishOrder] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    currentRaceId,
    race,
    setRace,
    selectedHorseIds,
    selectedHorseNames,
    setSelectedHorseNames,
  } = useContext(RaceContext);

  const [images, setImages] = useState([]);
  const [showTrack, setShowTrack] = useState(false);
  const [loading, setLoading] = useState(true);
  const [horseIds, setHorseIds] = useState(selectedHorseIds);
  const [horseSpeeds, setHorseSpeeds] = useState([]);
  const [horseDurations, setHorseDurations] = useState([]);
  const [numHorses, setNumHorses] = useState(9);
  const [selectedHorseNumbers, setSelectedHorseNumbers] = useState([]);
  const [wirePosition, setWirePosition] = useState(0);
  const [horsePositions, setHorsePositions] = useState([]);
  const [winnerDeclared, setWinnerDeclared] = useState(false);
  const raceTrackRef = useRef(null);
  const [leftEdge, setLeftEdge] = useState(0);
  const [rightEdge, setRightEdge] = useState(0);
  const [winnerNumber, setWinnerNumber] = useState(null);
  const [winnerName, setWinnerName] = useState(null);
  const [raceStartTime, setRaceStartTime] = useState(null);
  const [raceEndTime, setRaceEndTime] = useState(null);
  const [raceDuration, setRaceDuration] = useState(null);
  const [horseTimes, setHorseTimes] = useState([]);
  const raceResultSubmitted = useRef(false); // Add this ref to track submission

  const fetchRace = async () => {
    try {
      if (!currentRaceId) {
        console.error("Invalid race ID");
        return;
      }
      const response = await axios.get(`${config.backendUrl}/current_races/${currentRaceId}`);
      const raceData = response.data;
      setRace(raceData);
      setNumHorses(raceData.num_horses);
    } catch (error) {
      console.error("Non-Betting Race Selected");
    }
  };

  useEffect(() => {
    if (raceStartTime) {
      console.log("Race started at:", raceStartTime);
    }
  }, [raceStartTime]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${config.backendUrl}/images`);
        const allImages = response.data;
        const shuffledImages = shuffleArray(allImages);
        const selectedImages = shuffledImages.slice(0, numHorses);
        setImages(selectedImages);

        const initialSpeeds = selectedImages.map(() => {
          const currentSpeed = Math.random() * 1600 + 700; // Adjusted speed range  200+600
          const maxChange = currentSpeed * 7.07;  // 2.0
          const previousSpeed = currentSpeed * (1 - Math.random() * 0.7); // .2 Previous speed within 20% of current speed
          const newSpeed = Math.max(
            Math.min(currentSpeed, previousSpeed + maxChange),
            previousSpeed - maxChange
          );
          return newSpeed;
        });

        setHorseSpeeds(initialSpeeds);

        const randomDurations = selectedImages.map(() => {
          const randomDuration = Math.random() * 40 + 60; // Adjusted duration range
          return randomDuration.toFixed(2);
        });
        setHorseDurations(randomDurations);

        const imagePromises = selectedImages.map((image) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              resolve();
            };
            img.onerror = (error) => {
              console.log(
                `Error loading image: ${config.backendUrl}/images/${image.image_name}`,
                error
              );
              reject(error);
            };
            img.src = config.backendUrl + "/images/" + image.image_name;
          });
        });

        await Promise.all(imagePromises);
      } catch (error) {
        console.log("Error during image preloading:", error);
      }

      setLoading(false);
    };

    fetchImages();

    setTimeout(() => {
      setShowTrack(true);
      const startTime = performance.now();
      setRaceStartTime(startTime);
    }, 2000);

    fetchRace();

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, [numHorses, currentRaceId]);

  useEffect(() => {
    const targetPosition = window.innerWidth * 0.98;
    let intervalId;
    
    // Dynamic Speed Adjustment
    const calculateSpeed = (baseSpeed, progress) => {
      if (progress < 0.3) {
        return baseSpeed * (1 + Math.random() * 1.73); // Acceleration phase .1
      } else if (progress > 0.7) {
        return baseSpeed * (0.7 - Math.random() * 0.8); // Deceleration phase  .2
      }
      return baseSpeed * (1 + (Math.random() - 0.3) * 0.9); // .5 .3 Small random fluctuations during constant speed phase
    };

    const updateHorseSpeeds = () => {
      const updatedSpeeds = horsePositions.map((horse, index) => {
        const progress = horse.position / targetPosition; // Progress from 0 to 1
        return calculateSpeed(horseSpeeds[index], progress);
      });
      setHorseSpeeds(updatedSpeeds);
    };

    const updateHorsePositions = () => {
      const updates = horsePositions.map((horse, index) => {
        if (horse.position >= targetPosition && !finishOrder.some(f => f.index === index)) {
          const finishTime = performance.now();
          const horseDuration = (finishTime - raceStartTime) / 1000;
          return {
            index,
            position: horse.position,
            timestamp: finishTime,
            duration: horseDuration.toFixed(4),
            name: selectedHorseNames[index],
            number: selectedHorseNumbers[index],
            id: horseIds[index]
          };
        }

        let newPosition = horse.position + horseSpeeds[index] * 0.05;

        // Overtaking logic
        for (let i = 0; i < horsePositions.length; i++) {
          if (i !== index && Math.abs(horsePositions[i].position - newPosition) < 220) { // Increase overtaking distance
            newPosition += Math.random() * 120; // Increased temporary speed boost for overtaking
          }
        }

        return {
          ...horse,
          position: newPosition,
        };
      });

      setHorsePositions(updates);

      if (updates.some((horse) => horse.position >= targetPosition)) {
        setFinishOrder((prev) => {
          const newOrder = [
            ...prev,
            ...updates.filter((horse, index) => horse.position >= targetPosition && !finishOrder.some(f => f.index === index))
          ].sort((a, b) => a.timestamp - b.timestamp);
          setHorseTimes(newOrder.map(order => order.duration));
          return newOrder;
        });
        setWinnerDeclared(true);
        setRaceEndTime(performance.now());
      }

      if (finishOrder.length === horsePositions.length && horsePositions.length > 0) {
        clearInterval(intervalId);
        if (finishOrder.length > 0) {
          setWinnerNumber(finishOrder[0].number);
          setWinnerName(finishOrder[0].name);
        }
        if (raceStartTime && raceEndTime) {
          const totalDuration = ((raceEndTime - raceStartTime) / 1000).toFixed(4);
          setRaceDuration(totalDuration);
          console.log("Race Duration (s):", totalDuration);
          // Fetch the race result ID and navigate to the results page
          if (!raceResultSubmitted.current) {
            raceResultSubmitted.current = true;
            const fetchRaceResultId = async () => {
              try {
                const response = await axios.post(`${config.backendUrl}/race_results`, {
                  race_id: currentRaceId,
                  horse_ids: horseIds,
                  horse_names: selectedHorseNames,
                  num_horses: numHorses,
                  winner_number: finishOrder[0].number,
                  race_positions: finishOrder.map((horse) => ({
                    id: horse.id,
                    position: horse.position,
                  })),
                  horse_positions: horsePositions.map((horse) => horse.position),
                  wire_position: wirePosition,
                  wire_horse_positions: horsePositions.map(
                    (horsePosition) => wirePosition - horsePosition.position
                  ),
                  winner_name: finishOrder[0].name,
                  race_duration: raceDuration,
                  finish_order: finishOrder.map(horse => horse.number),
                  first: finishOrder[0]?.number || null,
                  second: finishOrder[1]?.number || null,
                  third: finishOrder[2]?.number || null,
                  fourth: finishOrder[3]?.number || null,
                  horse_times: horseTimes,
                  owner: horseIds, // Adjust according to actual data
                  jockey: horseIds, // Adjust according to actual data
                  trainer: horseIds, // Adjust according to actual data
                });
                const raceResultId = response.data.race_result_id;
                setTimeout(() => {
                  navigate('/race-results', {
                    state: { race_result_id: raceResultId }
                  });
                }, 5000); // Navigate after 5 seconds
              } catch (error) {
                console.error("Error fetching race result ID:", error);
              }
            };

            fetchRaceResultId();
          }
        }
      }
    };
    
    // Smoother Update Intervals
    intervalId = setInterval(() => {
      updateHorseSpeeds();
      updateHorsePositions();
    }, 50); // Update every 50ms for smoother animations
  
    return () => clearInterval(intervalId);
  }, [horsePositions, finishOrder, raceStartTime, raceEndTime, selectedHorseNames, selectedHorseNumbers, horseIds, navigate]);

  useEffect(() => {
    if (raceEndTime && raceStartTime) {
      const duration = (raceEndTime - raceStartTime) / 1000;
      setRaceDuration(duration.toFixed(4));
    }
  }, [raceEndTime, raceStartTime]);

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * Math.min(i, 5));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  function getRandomValue(min, max) {
    const range = max - min;
    const variation = range * 9;
    const randomOffset = Math.random() * variation - variation / .1;
    return (Math.random() * range + min + randomOffset).toFixed(2);
  }

  const updateRaceTracker = () => {
    const updatedHorsePositions = images.map((image, index) => {
      const horseElement = raceTrackRef.current.children[index];
      const boundingRect = horseElement.getBoundingClientRect();
      const rightEdge = boundingRect.left + boundingRect.width;
      return {
        id: image.id,
        position: rightEdge,
      };
    });

    setHorsePositions(updatedHorsePositions);

    const horsePositions = images.map((image, index) => {
      const horseElement = raceTrackRef.current.children[index];
      const boundingRect = horseElement.getBoundingClientRect();
      const rightEdge = boundingRect.left + boundingRect.width;
      return {
        id: image.id,
        position: rightEdge,
        horseIndex: index,
      };
    });

    horsePositions.sort((a, b) => b.position - a.position);

    const updatedHorseNames = horsePositions.map((horse) => {
      return selectedHorseNames[horse.horseIndex];
    });

    const indexMatchedToHorseName = selectedHorseNames.map(
      (name, index) => index + 1
    );

    const updatedHorseNumbers = horsePositions.map((horse) => {
      return indexMatchedToHorseName[horse.horseIndex];
    });

    setSelectedHorseNames(updatedHorseNames);
    setSelectedHorseNumbers(updatedHorseNumbers);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRaceTracker();
    }, 500);

    return () => clearInterval(intervalId);
  }, [images]);

  const containerHeight = 520;

  useEffect(() => {
    const updateWirePosition = () => {
      if (typeof window !== 'undefined') {
        const containerWidth = window.innerWidth || document.documentElement.clientWidth;
        const position = containerWidth * 0.98;
        setWirePosition(position);
      }
    };
    updateWirePosition();
    window.addEventListener('resize', updateWirePosition);

    return () => {
      window.removeEventListener('resize', updateWirePosition);
    };
  }, []);

  useEffect(() => {
    const updateEdges = () => {
      setLeftEdge('0');
      setRightEdge(window.innerWidth);
    };
    updateEdges();
    window.addEventListener('resize', updateEdges);

    return () => {
      window.removeEventListener('resize', updateEdges);
    };
  }, []);

  return (
    <>
      <RaceResultsHandler
        currentRaceId={currentRaceId}
        horsePositions={horsePositions}
        winnerDeclared={winnerDeclared}
        horseIds={horseIds}
        numHorses={numHorses}
        horseNames={selectedHorseNames}
        wirePosition={wirePosition}
        winnerNumber={winnerNumber}
        winnerName={winnerName}
        finishOrder={finishOrder}
        raceDuration={raceDuration}
        horseTimes={horseTimes}
      />
      <PreloadedImages horseImages={images}>
        {(startImage, gateImage, trackImage) => (
          <div className="race-screens-container">
            <figure className="track-container">
              <img
                className={`track-left ${showTrack ? "fade-out" : ""}`}
                src={config.backendUrl + "/images/start.gif"}
                alt="Start"
                style={{ width: '101vw', marginTop: '-5vh' }}
              />
              <img
                className={`track-left start-gate ${showTrack ? "fade-out" : ""}`}
                src={config.backendUrl + "/images/startGate.gif"}
                alt="StartGate"
                style={{ marginLeft: "0vw", marginTop: "-10vh", height: "116vh", width: "198vw", opacity: showTrack ? 0 : 1, transition: "opacity 0.2s ease", zIndex: '998' }}
              />
              {showTrack && (
                <img
                  className={`track-right ${showTrack ? "fade-in" : ""}`}
                  src={config.backendUrl + "/images/track.gif"}
                  alt="Track"
                  style={{ width: '100vw', marginTop: '-5vh' }}
                />
              )}
            </figure>
            <div className="race-screens-container" style={{ zIndex: '5' }}>
              <figure className="race-screen-race-track" ref={raceTrackRef}>
                {images.map((image, index) => {
                  const topPosition = `${(9.5 + index * .4) * containerHeight / 100}vh`;
                  return (
                    <figure
                      key={image.id}
                      className="horse"
                      style={{
                        position: "absolute",
                        top: topPosition,
                        left: ('-12vw'),
                        animationDuration: `${horseDurations[index] * 1.3}s`,
                        animationTimingFunction: `cubic-bezier(
                          ${getRandomValue(0.3, 0.6)},
                          ${getRandomValue(.001, 0.9555)},
                          ${getRandomValue(0, 0.799)},
                          ${getRandomValue(0, 0.999)})`,
                      }}
                    >
                      <div>
                        <img
                          className="race-screen-horse-container"
                          src={config.backendUrl + "/images/" + image.image_name}
                          alt={image.image_name}
                          style={{
                            display: 'inline-block',
                            height: "22vh",
                            width: "auto"
                          }}
                        />
                        <div
                          className="horse-number"
                          style={{
                            position: "absolute",
                            top: "6vh",
                            left: "11vW",
                            transform: "translate(-50%, -50%)",
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#fff",
                            fontSize: "18px",
                            fontWeight: "bold"
                          }}
                        >
                          {selectedHorseNumbers[index]}
                        </div>
                      </div>
                    </figure>
                  );
                })}
              </figure>

              {!loading && showTrack && (
                <div className="race-tracker-overlay" style={{ width: "fit-content", padding: "1%" }}>
                  <h2 style={{ marginTop: "-8vh" }}>RaceTracker</h2>
                  <h3
                    style={{
                      paddingLeft: "auto",
                      marginBottom: "-1vh",
                      marginTop: "-2vh",
                    }}
                  >
                    Race #{currentRaceId}
                  </h3>
                  <ul>
                    {selectedHorseNames.map((horseName, index) => {
                      return (
                        <li key={index}>
                          {index + 1} - {selectedHorseNumbers[index]} - {horseName}
                        </li>
                      );
                    })}
                  </ul>

                  <div style={{ zIndex: "99999", top: "28vh" }}>
                    {winnerDeclared && <p></p>}
                    <ul>
                      {finishOrder.slice(0, 4).map((horse, index) => {
                        let position;
                        switch (index) {
                          case 0:
                            position = " WINNER ";
                            break;
                          case 1:
                            position = "2nd";
                            break;
                          case 2:
                            position = "3rd";
                            break;
                          case 3:
                            position = "4th";
                            break;
                          default:
                            position = `${index + 1}th`;
                        }
                        return (
                          <li key={horse.index} style={{ listStyleType: "none" }}>
                            {position}:  #{horse.number} - {horse.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </PreloadedImages>
      <div id="wire"
        style={{
          position: "absolute",
          top: "35vh",
          right: "2%",
          width: ".5vw",
          height: "30vh",
          background: "linear-gradient(to bottom, black, white)",
        }}
      ></div>
    </>
  );
}

export default Race;
