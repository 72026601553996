
// DataConsumer.js  TEST CODE FOR RACECONTEXT PROVIDER 
import React, { useContext } from 'react';
import { RaceContext } from '../Pages/RaceContext';

const DataConsumer = () => {
  const { race, currentRaceId, navigateData } = useContext(RaceContext);

  return (
    <div>
      <h2>Data Consumer</h2>
      <p>Race Data:</p>
      <pre>{JSON.stringify(race, null, 2)}</pre>
      <p>Current Race ID: {currentRaceId}</p>
      <p>Navigate Data:</p>
      <pre>{JSON.stringify(navigateData, null, 2)}</pre>
    </div>
  );
};

export default DataConsumer;
